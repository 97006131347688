import axios from "axios"
import { IPersonnelDdcModel, IPersonnelDdcSuiviModel } from "../../../../../../common/models"
import {
     CONST_API_PERSONNEL_DECLARATION_DDC_ANNULER_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_DDC_CREATE_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_DDC_EDIT_ENDPOINT,
     CONST_API_PERSONNEL_DECLARATION_DDC_SUIVI_ENDPOINT,
} from "../../../../../endpoints"
import { ICreateConnectedUserDdcRequestModel, IEditConnectedUserDdcRequestModel } from "./_models"
import { CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE } from "../../../../../../common/constants"

export function createConnectedUserDdcRequest(payload: ICreateConnectedUserDdcRequestModel) {
     const formData = new FormData()
     formData.append("data[du]", payload.du as string)
     formData.append("data[au]", payload.au as string)
     formData.append("data[type]", payload.type as string)
     if (payload.type === CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE) {
          if (payload.motif) formData.append("data[motif]", payload.motif)
          if (payload.justificatif) formData.append("justificatif", payload.justificatif)
          formData.append("data[sousType]", payload.sousType as string)
     }
     return axios.post<IPersonnelDdcModel>(CONST_API_PERSONNEL_DECLARATION_DDC_CREATE_ENDPOINT, formData)
}

export function editConnectedUserDdcRequest(id: number, payload: IEditConnectedUserDdcRequestModel) {
     const formData = new FormData()
     formData.append("data[du]", payload.du as string)
     formData.append("data[au]", payload.au as string)
     formData.append("data[type]", payload.type as string)
     if (payload.type === CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE) {
          formData.append("data[voulezVousChangerLaPieceJointe]", payload.voulezVousChangerLaPieceJointe ? "1" : "0")
          if (payload.motif) formData.append("data[motif]", payload.motif)
          if (payload.voulezVousChangerLaPieceJointe && payload.justificatif) formData.append("justificatif", payload.justificatif)
          formData.append("data[sousType]", payload.sousType as string)
     }
     return axios.post<IPersonnelDdcModel>(CONST_API_PERSONNEL_DECLARATION_DDC_EDIT_ENDPOINT(id), formData)
}

export function annulerConnectedUserDdcRequest(id: number, commentaire: string) {
     return axios.post<IPersonnelDdcModel>(CONST_API_PERSONNEL_DECLARATION_DDC_ANNULER_ENDPOINT(id), { commentaire })
}

export function getSuiviConnectedUserDdcRequest(id: number) {
     return axios.get<IPersonnelDdcSuiviModel[]>(CONST_API_PERSONNEL_DECLARATION_DDC_SUIVI_ENDPOINT(id))
}
