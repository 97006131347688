import { Slide, ToastOptions } from "react-toastify"

export const IS_DEV_ENV_ENABLED: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === "development"

/**************************************************************/
/**************************************************************/
/*                    BEGIN API-CORE CONSTANTS                */
/**************************************************************/
/**************************************************************/
/* HTTP Response custom codes to handle exceptions */
export const CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR = "FORM_VALIDATION_ERROR"

/* GENERAL */
export const CONST_CIVILITE_MR = "CIVILITE_MR"
export const CONST_CIVILITE_MME = "CIVILITE_MME"
export const CONST_CIVILITE_NON_RENSEIGNEE = "CIVILITE_NON_RENSEIGNEE"

/* ENTITY Annonce */
export const CONST_ANNONCE_ETAT_ON_CREATION = "ETAT_ON_CREATION"
export const CONST_ANNONCE_ETAT_ACTIVE = "ETAT_ACTIVE"
export const CONST_ANNONCE_ETAT_ARCHIVED = "ETAT_ARCHIVED"

/* ENTITY Personnel */
export const CONST_PERSONNEL_CODE_HUGO_DUTERTRE = "2412-3222-kda3-kmmm"
export const CONST_PERSONNEL_CODE_THOMAS_NAULEAU = "2222-1111-3333-aaaa"
export const CONST_PERSONNEL_CODE_CELINE_CORLE = "CELINE_CORLE"
export const CONST_PERSONNEL_CODE_DIDIER_BAGUR = "2222-8797-8999-aeds"
export const CONST_PERSONNEL_STATUT_STATUT_ETAM = "STATUT_ETAM"
export const CONST_PERSONNEL_STATUT_STATUT_AGENT_MAITRISE = "STATUT_AGENT_MAITRISE"
export const CONST_PERSONNEL_STATUT_STATUT_CADRE = "STATUT_CADRE"
export const CONST_PERSONNEL_STATUT_STATUT_NON_SALARIE = "STATUT_NON_SALARIE"
export const CONST_PERSONNEL_CONTRAT_CDI = "CONTRAT_CDI"
export const CONST_PERSONNEL_CONTRAT_CDD = "CONTRAT_CDD"
export const CONST_PERSONNEL_CONTRAT_NON_SALARIE = "CONTRAT_NON_SALARIE"

// ENTITY PersonnelApplicationAccess
export const CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_ADMINISTRATIF = "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_ADMINISTRATIF"
export const CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_BUSINESS_PTF = "PERSONNEL_APPLICATION_ACCESS_MENU_TARGET_MODULE_BUSINESS_PTF"

/* ENTITY ConsultantDESuivi*/
export const CONST_CONSULTANT_DE_SUIVI_TYPE_BROUILLON = "TYPE_BROUILLON"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_IA_RECTIFICATION_FOR_ADMIN = "TYPE_IA_RECTIFICATION_FOR_ADMIN"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_IA_RECTIFICATION_FOR_DG = "TYPE_IA_RECTIFICATION_FOR_DG"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_IA_RECTIFICATION_FOR_IARESP = "TYPE_IA_RECTIFICATION_FOR_IARESP"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_IA_DEMANDE_EMBAUCHE = "TYPE_IA_DEMANDE_EMBAUCHE"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_IARESP_VALIDATION = "TYPE_IARESP_VALIDATION"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_IARESP_DEMANDE_RECTIFICATION = "TYPE_IARESP_DEMANDE_RECTIFICATION"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_IARESP_REJET_DEFINITIF = "TYPE_IARESP_REJET_DEFINITIF"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_DG_VALIDATION = "TYPE_DG_VALIDATION"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_DG_DEMANDE_RECTIFICATION = "TYPE_DG_DEMANDE_RECTIFICATION"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_DG_REJET_DEFINITIF = "TYPE_DG_REJET_DEFINITIF"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_ADMIN_DEMANDE_RECTIFICATION = "TYPE_ADMIN_DEMANDE_RECTIFICATION"
export const CONST_CONSULTANT_DE_SUIVI_TYPE_ADMIN_VALIDATION = "TYPE_ADMIN_VALIDATION"

/* ENTITY PersonnelDdc */
export const CONST_PERSONNEL_DDC_TYPE_CONGES_PAYES = "TYPE_P_CONGES_PAYES"
export const CONST_PERSONNEL_DDC_TYPE_CONGES_SANS_SOLDE = "TYPE_P_CONGES_SANS_SOLDE"
export const CONST_PERSONNEL_DDC_TYPE_REPOS_COMPENSATEUR = "TYPE_P_REPOS_COMPENSATEUR"
export const CONST_PERSONNEL_DDC_TYPE_ABSENCE_AUTORISEE = "TYPE_P_ABSENCE_AUTORISEE"
export const CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE = "TYPE_P_ABSENCE_EXCEPTIONNELLE"
export const CONST_PERSONNEL_DDC_TYPE_ABSENCE_RTT = "TYPE_P_ABSENCE_RTT"

export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_PACS = "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_MARIAGE_PACS"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT = "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT = "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS = "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS = "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS =
     "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENT = "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_DECES_PARENT"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE =
     "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR = "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS =
     "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT =
     "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT =
     "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT"
export const CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_AUTRE = "SOUS_TYPE_P_ABSENCE_EXCEPTIONNELLE_AUTRE"

// -- PersonnelDeclarationMensuelle
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION = "TYPE_INTERESSE_FINALISATION"
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE = "TYPE_INTERESSE_SIGNATURE"
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE = "TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE"
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION = "TYPE_RESP_DEMANDE_RECTIFICATION"
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION = "TYPE_RESP_VALIDATION"
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDER_RECTIFICATION = "TYPE_SIEGE_DEMANDE_RECTIFICATION"
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION = "TYPE_SIEGE_VALIDATION"
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL = "TYPE_INTERESSE_DEMANDE_REGUL"
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL = "TYPE_RESP_VALIDATION_REGUL"
export const CONST_PERSONNEL_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL = "TYPE_RESP_REJET_REGUL"

/* ENTITY PersonnelCraDetails*/
export const CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_CPA = CONST_PERSONNEL_DDC_TYPE_CONGES_PAYES
export const CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_CSS = CONST_PERSONNEL_DDC_TYPE_CONGES_SANS_SOLDE
export const CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_CEX = CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE
export const CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_CMA = "P_ABSCENCE_CMA"
export const CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_FERIES = "P_ABSCENCE_FERIES"
export const CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_REPOS_COMPENSATEUR = CONST_PERSONNEL_DDC_TYPE_REPOS_COMPENSATEUR
export const CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_SFP = "P_ABSCENCE_SFP"
export const CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_RTT = CONST_PERSONNEL_DDC_TYPE_ABSENCE_RTT
export const CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_AUTORISEE = CONST_PERSONNEL_DDC_TYPE_ABSENCE_AUTORISEE

// ENTITY PersonnelCraDetailsHeures
export const CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_MAIN = "TYPE_MAIN"
export const CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_TT = "TYPE_TT"
export const CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_ALTERNANCE = "TYPE_ALTERNANCE"

/* ENTITY PersonnelNdfDetail */
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM = "CATEGORIE_KM"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_CARBURANT = "CATEGORIE_CARBURANT"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_PEAGE = "CATEGORIE_PEAGE"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_PARKING = "CATEGORIE_PARKING"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_AVION = "CATEGORIE_AVION"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_TRAIN = "CATEGORIE_TRAIN"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_TAXI = "CATEGORIE_TAXI"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_TRANSPORT_EN_COMMUN = "CATEGORIE_TRANSPORT_EN_COMMUN"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_HOTEL = "CATEGORIE_HOTEL"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI = "CATEGORIE_REPAS_MIDI"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_SOIR = "CATEGORIE_REPAS_SOIR"
export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_DIVERS = "CATEGORIE_DIVERS"

// Entity PersonnelDdcSuivi
export const CONST_PERSONNEL_DDC_SUIVI_TYPE_ENVOI_DEMANDE = "TYPE_ENVOI_DEMANDE"
export const CONST_PERSONNEL_DDC_SUIVI_TYPE_RECTIFICATION_AVANT_DECISION_RESPONSABLE = "TYPE_RECTIFICATION_AVANT_DECISION_RESPONSABLE"
export const CONST_PERSONNEL_DDC_SUIVI_TYPE_ANNULATION_DEMANDE = "TYPE_ANNULATION_DEMANDE"
export const CONST_PERSONNEL_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE = "TYPE_ACCEPTATION_DEMANDE"
export const CONST_PERSONNEL_DDC_SUIVI_TYPE_REJET_DEMANDE = "TYPE_REJET_DEMANDE"

/* Entity PersonnelTemplate */
export const CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PERSONNEL_MAIL_TEMPLATE_TYPE_CANDIDAT_REFUS = "PERSONNEL_MAIL_TEMPLATE_TYPE_CANDIDAT_REFUS"
export const CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_PARTAGER_DOCO_CANDIDAT_PAR_MAIL = "CONST_CODE_PARTAGER_DOCO_CANDIDAT_PAR_MAIL"
export const CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_ENVOYER_DOCO_VIERGE_PAR_MAIL_A_REMPLIR_PAR_CANDIDAT = "CONST_CODE_ENVOYER_DOCO_VIERGE_PAR_MAIL_A_REMPLIR_PAR_CANDIDAT"
export const CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_DMS = "CONST_CODE_DMS"
export const CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_CANDIDAT_ENTRETIEN_EN_AGENCE = "CONST_CODE_CANDIDAT_ENTRETIEN_EN_AGENCE"
export const CONST_PERSONNEL_TEMPLATE_CATEGORIE_CODE_CANDIDAT_REUNION_QUALIF = "CONST_CODE_CANDIDAT_REUNION_QUALIF"

/* ENTITY PersonnelRechercheCandidatHistoriqueSauvegarde */
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATION_PASVUPARMOI = "VISUALISATION_PASVUPARMOI"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATION_VUPARMOIETPARTEAM = "VISUALISATION_VUPARMOIETPARTEAM"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATION_NONVUPARMOIETNONVUPARTEAM = "VISUALISATION_NONVUPARMOIETNONVUPARTEAM"
/* ---- */
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_OPERATEUR_LOGIQUE_OU = "OPERATEUR_LOGIQUE_OU"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_OPERATEUR_LOGIQUE_ET = "OPERATEUR_LOGIQUE_ET"
/* ---- */
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_CV = "KEYWORDTARGET_CV"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_NOM_MAIL_TEL = "KEYWORDTARGET_NOM_MAIL_TEL"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_SUIVI = "KEYWORDTARGET_SUIVI"
/* ---- */
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVI_AVEC_MON_SUIVI = "SUIVI_AVEC_MON_SUIVI"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVI_SANS_MON_SUIVI = "SUIVI_SANS_MON_SUIVI"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVI_SANS_MON_SUIVI_NI_CELUI_COEQUIPIERS = "SUIVI_SANS_MON_SUIVI_NI_CELUI_COEQUIPIERS"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVI_AVEC_MON_SUIVI_OU_CELUI_COEQUIPIERS = "SUIVI_AVEC_MON_SUIVI_OU_CELUI_COEQUIPIERS"
/* ---- */
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_15_JOURS = "FRAICHEUR_CV_MOINS_15_JOURS"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_1_MOIS = "FRAICHEUR_CV_MOINS_1_MOIS"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_2_MOIS = "FRAICHEUR_CV_MOINS_2_MOIS"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_6_MOIS = "FRAICHEUR_CV_MOINS_6_MOIS"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_12_MOIS = "FRAICHEUR_CV_MOINS_12_MOIS"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_TOUTES = "FRAICHEUR_CV_TOUTES"
/* ---- */
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_SAUVEGARDE = "TYPE_SAUVEGARDE"
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_TYPE_HISTORIQUE = "TYPE_HISTORIQUE"

/* ENTITY Pays */
export const CONST_PAYS_CODE_FRANCE = 250
export const CONST_PAYS_ID_FRANCE = 75

/* ENTITY BusinessUnit */
export const CONST_BUSINESS_UNIT_SECRET_DEFENSE_CODE = "DEFENSE"

/* ENTITY Candidat_document */
export const CONST_CANDIDAT_DOCUMENT_TYPE_CV = "TYPE_CV"
export const CONST_CANDIDAT_DOCUMENT_TYPE_DOCO = "TYPE_DOSSIER_COMPETENCE"

/* ENTITY Consultant */
// Consultant
// -- etat
export const CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION = "ETAT_EN_COURS_EMBAUCHE_POUR_MISSION"
export const CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_SANS_MISSION = "ETAT_EN_COURS_EMBAUCHE_SANS_MISSION"
export const CONST_CONSULTANT_ETAT_EMBAUCHE_AVEC_MISSION = "ETAT_EMBAUCHE_AVEC_MISSION"
export const CONST_CONSULTANT_ETAT_EMBAUCHE_INACTIF = "ETAT_EMBAUCHE_INACTIF"
export const CONST_CONSULTANT_ETAT_HORS_EFFECTIF = "ETAT_HORS_EFFECTIF"
export const CONST_CONSULTANT_ETAT_EMBAUCHE_SANS_MISSION = "ETAT_EMBAUCHE_SANS_MISSION"
// -- statut
export const CONST_CONSULTANT_STATUT_ETAM = "STATUT_ETAM"
export const CONST_CONSULTANT_STATUT_AGENT_MAITRISE = "STATUT_AGENT_MAITRISE"
export const CONST_CONSULTANT_STATUT_CADRE = "STATUT_CADRE"
export const CONST_CONSULTANT_STATUT_ST_PORTAGE = "STATUT_ST_PORTAGE"
export const CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE = "STATUT_ST_SOCIETE_SERVICE"
// -- Contrat
export const CONST_CONSULTANT_CONTRAT_CDI = "CONTRAT_CDI"
export const CONST_CONSULTANT_CONTRAT_CDD = "CONTRAT_CDD"
export const CONST_CONSULTANT_CONTRAT_CDIC = "CONTRAT_CDIC"
export const CONST_CONSULTANT_CONTRAT_ST = "CONTRAT_ST"

/* ENTITY ConsultantDE */
export const CONST_CONSULTANT_DE_DISPOSITIF_POLE_EMPLOI_AFPR = "AFPR"
export const CONST_CONSULTANT_DE_DISPOSITIF_POLE_EMPLOI_PMSMP = "PMSMP"

/* ENTITY ConsultantDocument */
export const CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI = "TYPE_PASSEPORT_CNI"
export const CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR = "TYPE_TITRE_SEJOUR"
export const CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS = "TYPE_ATTESTATION_SS"
export const CONST_CONSULTANT_DOCUMENT_TYPE_RIB = "TYPE_RIB"
export const CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT = "TYPE_CONTRAT"
export const CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT = "TYPE_AVENANT_CONTRAT"
export const CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE = "TYPE_PERMIS_CONDUIRE"
export const CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME = "TYPE_DIPLOME"
export const CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES = "TYPE_AUTRES"

/* ENTITY Candidat */
export const CONST_MOBILITE_FRANCE = "MOBILITE_FRANCE"
export const CONST_MOBILITE_FRANCE_HORS_IDF = "MOBILITE_FRANCE_HORS_IDF"
export const CONST_MOBILITE_IDF = "MOBILITE_IDF"
export const CONST_MOBILITE_DEPARTEMENTAL = "MOBILITE_DEPARTEMENTAL"
export const CONST_MOBILITE_REGIONAL = "MOBILITE_REGIONAL"
export const CONST_MOBILITE_PAR_VILLE = "MOBILITE_PAR_VILLE"
export const CONST_MOBILITE_INTERNATIONAL = "MOBILITE_INTERNATIONAL"
export const CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE = "DISPONIBILITE_DATE_EXACTE"
/* ---- */
export const CONST_CANDIDAT_ETAT_EMBAUCHE = "ETAT_EMBAUCHE"
/* ---- */
export const CONST_CANDIDAT_MOBILITE_DEPARTEMENTAL = "MOBILITE_DEPARTEMENTAL"
export const CONST_CANDIDAT_MOBILITE_REGIONAL = "MOBILITE_REGIONAL"
export const CONST_CANDIDAT_MOBILITE_PAR_VILLE = "MOBILITE_PAR_VILLE"
/* ---- */
export const CONST_CANDIDAT_TYPE_POSTULANT = "TYPE_POSTULANT"
export const CONST_CANDIDAT_TYPE_CS = "TYPE_CS"
export const CONST_CANDIDAT_TYPE_SAISI = "TYPE_SAISI"
/* ---- */
export const CONST_CANDIDAT_EXPERIENCE_0A1_ANS = "EXPERIENCE_0A1_ANS"
export const CONST_CANDIDAT_EXPERIENCE_1A2_ANS = "EXPERIENCE_1A2_ANS"
export const CONST_CANDIDAT_EXPERIENCE_2A5_ANS = "EXPERIENCE_2A5_ANS"
export const CONST_CANDIDAT_EXPERIENCE_5A7_ANS = "EXPERIENCE_5A7_ANS"
export const CONST_CANDIDAT_EXPERIENCE_7A10_ANS = "EXPERIENCE_7A10_ANS"
export const CONST_CANDIDAT_EXPERIENCE_10A15_ANS = "EXPERIENCE_10A15_ANS"
export const CONST_CANDIDAT_EXPERIENCE_15PLUS_ANS = "EXPERIENCE_15PLUS_ANS"
/* ---- */
export const CONST_NIVEAU_ETUDES_TECHNICIEN = "NIVEAU_ETUDES_TECHNICIEN"
export const CONST_NIVEAU_ETUDES_INGE = "NIVEAU_ETUDES_INGE"
/* ---- */
export const CONST_DISPONIBILITE_IMMEDIATE = "DISPONIBILITE_IMMEDIATE"
export const CONST_DISPONIBILITE_PREAVIS_EN_COURS = "DISPONIBILITE_PREAVIS_EN_COURS"
export const CONST_DISPONIBILITE_PREAVIS_2_SEMAINES = "DISPONIBILITE_PREAVIS_2_SEMAINES"
export const CONST_DISPONIBILITE_PREAVIS_1_MOIS = "DISPONIBILITE_PREAVIS_1_MOIS"
export const CONST_DISPONIBILITE_PREAVIS_2_MOIS = "DISPONIBILITE_PREAVIS_2_MOIS"
export const CONST_DISPONIBILITE_PREAVIS_3_MOIS = "DISPONIBILITE_PREAVIS_3_MOIS"
export const CONST_DISPONIBILITE_DATE_EXACTE = "DISPONIBILITE_DATE_EXACTE"

/* ENTITY CandidatStatus */
export const CONST_CANDIDATSTATUS_CONTACTE_OR_NOT_RETENU_OPTION_NON_RETENU = "CONTACTE_OR_NOT_RETENU_OPTION_NON_RETENU"
export const CONST_CANDIDATSTATUS_CONTACTE_OR_NOT_RETENU_OPTION_CONTACTE = "CONTACTE_OR_NOT_RETENU_OPTION_CONTACTE"
export const CONST_CANDIDATSTATUS_CONTACTE_PAR_EMAIL = "CONTACTE_PAR_EMAIL"
export const CONST_CANDIDATSTATUS_CONTACTE_PAR_TEL = "CONTACTE_PAR_TEL"
export const CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_MOBILITE = "NON_RETENU_CAUSE_MOBILITE"
export const CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_COMPETENCES = "NON_RETENU_CAUSE_COMPETENCES"
export const CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_DISPO = "NON_RETENU_CAUSE_DISPO"
export const CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REMUNERATION = "NON_RETENU_CAUSE_REMUNERATION"
export const CONST_CANDIDATSTATUS_NON_RETENU_CAUSE_REFUS_CANDIDAT = "NON_RETENU_CAUSE_REFUS_CANDIDAT"

/* ENTITY CandidatSuivi*/
export const CONST_CANDIDATSUIVI_TYPE_MESSAGE_REPONDEUR = "TYPE_MESSAGE_REPONDEUR"
export const CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_TELEPHONIQUE = "TYPE_ENTRETIEN_TELEPHONIQUE"
export const CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CTS = "TYPE_ENTRETIEN_CTS"
export const CONST_CANDIDATSUIVI_TYPE_ENTRETIEN_CLIENT = "TYPE_ENTRETIEN_CLIENT"

/* ENTITY ConsultantDeclarationMensuelleSuivi */
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME = "TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION = "TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME = "TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE =
     "TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE =
     "TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE =
     "TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE"
export const CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT = "TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM =
     "TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM =
     "TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE =
     "TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM = "TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM"

export const CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE = "TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE"
export const CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE = "TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE"
export const CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE = "TYPE_VALIDATION_PAR_SIEGE"
export const CONST_DECLARATION_SUIVI_TYPE_DEMANDE_REGUL_PAR_CONSULTANT = "TYPE_DEMANDE_REGUL_PAR_CONSULTANT"
export const CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP = "TYPE_VALIDATION_REGUL_PAR_RESP"
export const CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP = "TYPE_REJET_REGUL_PAR_RESP"
export const CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL = "TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL"
export const CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL =
     "TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL"

/* ENTITY ConsultantDdcSuivi */
export const CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE = "TYPE_ENVOI_DEMANDE"
export const CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE = "TYPE_ACCEPTATION_DEMANDE"
export const CONST_DDC_SUIVI_TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM = "TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM"
export const CONST_DDC_SUIVI_TYPE_REJET_DEMANDE = "TYPE_REJET_DEMANDE"
export const CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE = "TYPE_ANNULATION_DEMANDE"

/* ENTITY ConsultantMission*/
export const CONST_CONSULTANT_MISSION_REFERENCE_FORFAIT_GENERIQUE_POUR_CRA = "FORFAIT"

/* ENTITY ConsultantDdc*/
export const CONST_DDC_TYPE_CONGES_PAYES = "TYPE_CONGES_PAYES"
export const CONST_DDC_TYPE_CONGES_SANS_SOLDE = "TYPE_CONGES_SANS_SOLDE"
export const CONST_DDC_TYPE_REPOS_COMPENSATEUR = "TYPE_REPOS_COMPENSATEUR"
export const CONST_DDC_TYPE_ABSENCE_AUTORISEE = "TYPE_ABSENCE_AUTORISEE"
export const CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE = "TYPE_ABSENCE_EXCEPTIONNELLE"
export const CONST_DDC_TYPE_ABSENCE_JOUR_NON_TRAVAILLE_FORFAIT = "TYPE_ABSENCE_JOUR_NON_TRAVAILLE_FORFAIT"
export const CONST_DDC_TYPE_ABSENCE_RTT = "ABSENCE_RTT"

/* ENTITY ConsultantCraDetails*/
export const CONST_CRA_DETAILS_ABSCENCE_CPA = CONST_DDC_TYPE_CONGES_PAYES
export const CONST_CRA_DETAILS_ABSCENCE_CSS = CONST_DDC_TYPE_CONGES_SANS_SOLDE
export const CONST_CRA_DETAILS_ABSCENCE_JOURS_NON_TRAVAILLES_FORFAIT = CONST_DDC_TYPE_ABSENCE_JOUR_NON_TRAVAILLE_FORFAIT
export const CONST_CRA_DETAILS_ABSCENCE_CEX = CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE
export const CONST_CRA_DETAILS_ABSCENCE_CMA = "ABSCENCE_CMA"
export const CONST_CRA_DETAILS_ABSCENCE_FERIES = "ABSCENCE_FERIES"
export const CONST_CRA_DETAILS_ABSCENCE_REPOS_COMPENSATEUR = CONST_DDC_TYPE_REPOS_COMPENSATEUR
export const CONST_CRA_DETAILS_ABSCENCE_SFP = "ABSCENCE_SFP"
export const CONST_CRA_DETAILS_ABSCENCE_RTT = CONST_DDC_TYPE_ABSENCE_RTT
export const CONST_CRA_DETAILS_ABSCENCE_AUTORISEE = CONST_DDC_TYPE_ABSENCE_AUTORISEE

/* ENTITY Entite */
export const CONST_ENTITE_CODE_AIRFRAME = "CODE_AIRFRAME"
export const CONST_ENTITE_CODE_AIRSYS = "CODE_AIRSYS"
export const CONST_ENTITE_CODE_CTS = "CODE_CTS"
export const CONST_ENTITE_CODE_GS = "CODE_GS"
export const CONST_ENTITE_CODE_IT = "CODE_IT"
export const CONST_ENTITE_CODE_NATESYS = "CODE_NATESYS"
export const CONST_ENTITE_CODE_NEO = "CODE_NEO"
export const CONST_ENTITE_CODE_NORD = "CODE_NORD"
export const CONST_ENTITE_CODE_SENSE = "CODE_SENSE"

/* ENTITY ConsultantNdfDetail */
export const CONST_NDF_DETAILS_CATEGORIE_KM = "CATEGORIE_KM"
export const CONST_NDF_DETAILS_CATEGORIE_CARBURANT = "CATEGORIE_CARBURANT"
export const CONST_NDF_DETAILS_CATEGORIE_PEAGE = "CATEGORIE_PEAGE"
export const CONST_NDF_DETAILS_CATEGORIE_PARKING = "CATEGORIE_PARKING"
export const CONST_NDF_DETAILS_CATEGORIE_AVION = "CATEGORIE_AVION"
export const CONST_NDF_DETAILS_CATEGORIE_TRAIN = "CATEGORIE_TRAIN"
export const CONST_NDF_DETAILS_CATEGORIE_TAXI = "CATEGORIE_TAXI"
export const CONST_NDF_DETAILS_CATEGORIE_TRANSPORT_EN_COMMUN = "CATEGORIE_TRANSPORT_EN_COMMUN"
export const CONST_NDF_DETAILS_CATEGORIE_HOTEL = "CATEGORIE_HOTEL"
export const CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI = "CATEGORIE_REPAS_MIDI"
export const CONST_NDF_DETAILS_CATEGORIE_REPAS_SOIR = "CATEGORIE_REPAS_SOIR"
export const CONST_NDF_DETAILS_CATEGORIE_DIVERS = "CATEGORIE_DIVERS"

/* ENTITY Fournisseur*/
export const CONST_FOURNISSEUR_FORME_JURIDIQUE_AUTOENTREPREURE = "AUTOENTREPREURE"
export const CONST_FOURNISSEUR_FORME_JURIDIQUE_SAS = "SAS"
export const CONST_FOURNISSEUR_FORME_JURIDIQUE_SASU = "SASU"
export const CONST_FOURNISSEUR_FORME_JURIDIQUE_SARL = "SARL"
export const CONST_FOURNISSEUR_FORME_JURIDIQUE_EURL = "EURL"
export const CONST_FOURNISSEUR_FORME_JURIDIQUE_BICBNC = "BICBNC"
export const CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE = "SOCIETE_PORTAGE"
export const CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE = "SOCIETE_SERVICE"

/* ENTITY Fournisseur Document*/
export const CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS = "TYPE_FOURNISSEUR_EXTRAIT_KBIS"
export const CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO = "TYPE_FOURNISSEUR_ASSURANCE_RC_PRO"
export const CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF = "TYPE_FOURNISSEUR_VIGILANCE_URSSAF"
export const CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE = "TYPE_FOURNISSEUR_REGULARITE_FISCALE"
export const CONST_FOURNISSEUR_DOCUMENT_RIB = "TYPE_FOURNISSEUR_RIB"

// ENTITY ClientPropal
export const CONST_CLIENT_PROPAL_ETAT_VALIDEE = "ETAT_VALIDEE"
export const CONST_CLIENT_PROPAL_ETAT_EN_COURS = "ETAT_EN_COURS"
export const CONST_CLIENT_PROPAL_ETAT_REFUSEE = "ETAT_REFUSEE"
export const CONST_CLIENT_PROPAL_ETAT_DISABLED = "ETAT_DISABLED"
export const CONST_CLIENT_PROPAL_ETAT_EN_PROD = "ETAT_EN_PROD"
export const CONST_CLIENT_PROPAL_ETAT_PARTIELLEMENT_LIVRE = "ETAT_PARTIELLEMENT_LIVRE"
export const CONST_CLIENT_PROPAL_ETAT_LIVRE = "ETAT_LIVRE"
export const CONST_CLIENT_PROPAL_TYPE_FORFAIT = "TYPE_FORFAIT"
export const CONST_CLIENT_PROPAL_TYPE_ASSISTANCE_TECHNIQUE = "TYPE_ASSISTANCE_TECHNIQUE"
export const CONST_CLIENT_PROPAL_CATEGORIE_MERE = "CATEGORIE_MERE"
export const CONST_CLIENT_PROPAL_CATEGORIE_FILLE = "CATEGORIE_FILLE"
export const CONST_CLIENT_PROPAL_CATEGORIE_ORPHELINE = "CATEGORIE_ORPHELINE"

// ENTITY ClientBonCommande
export const CONST_CLIENT_BON_COMMANDE_TYPE_MAIL_CONFIRMATION = "TYPE_MAIL_CONFIRMATION"
export const CONST_CLIENT_BON_COMMANDE_TYPE_BC_ORIGINAL = "TYPE_BC_ORIGINAL"

// ENTITY ClientBonLivraison
export const CONST_CLIENT_BON_LIVRAISON_ETAT_REDIGE = "ETAT_REDIGE"
export const CONST_CLIENT_BON_LIVRAISON_ETAT_EN_COURS = "ETAT_EN_COURS"
export const CONST_CLIENT_BON_LIVRAISON_ETAT_ENVOYE_AU_CLIENT = "ETAT_ENVOYE_AU_CLIENT"
export const CONST_CLIENT_BON_LIVRAISON_ETAT_RECU_AVEC_SIGNATURE_CLIENT = "ETAT_RECU_AVEC_SIGNATURE_CLIENT"
export const CONST_CLIENT_BON_LIVRAISON_ETAT_FACTURE = "ETAT_FACTURE"
export const CONST_CLIENT_BON_LIVRAISON_ETAT_PAYE = "ETAT_PAYE"

// ENTITY ConsultantCRaDetails
export const CONST_CONSULTANT_CRA_DETAILS_ETAT_EN_MISSION = "ETAT_EN_MISSION"
export const CONST_CONSULTANT_CRA_DETAILS_ETAT_INACTIF = "ETAT_INACTIF"
export const CONST_CONSULTANT_CRA_DETAILS_ETAT_HORS_EFFECTIF = "ETAT_HORS_EFFECTIF"

// ENTITY ConsultantMission
export const CONST_CONSULTANT_MISSION_TYPE_PERMANENT = "TYPE_PERMANENT"
export const CONST_CONSULTANT_MISSION_TYPE_PONCTUEL = "TYPE_PONCTUEL"
export const CONST_CONSULTANT_MISSION_TYPE_ST = "TYPE_ST"

// ENTITY ConsultantSuivi
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_SOLDE_TOUT_COMPTE = "TYPE_SOLDE_TOUT_COMPTE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_CLIENT = "TYPE_CHANGEMENT_CLIENT"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_ENVOYER_MAIL_INSTRUCTIONS_CONNEXION_ESPACE_COLLAB = "TYPE_ENVOYER_MAIL_INSTRUCTIONS_CONNEXION_ESPACE_COLLAB"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_EVOLUTION_TJMST = "TYPE_EVOLUTION_TJMST"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_EVOLUTION_SALAIRE_BRUT = "TYPE_EVOLUTION_SALAIRE_BRUT"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MANAGER = "TYPE_CHANGEMENT_MANAGER"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MACHINE = "TYPE_CHANGEMENT_MACHINE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_DATE_EFD = "TYPE_AJOUT_DATE_EFD"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_PRIME = "TYPE_AJOUT_PRIME"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_REMARQUE = "TYPE_AJOUT_REMARQUE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_COEFF = "TYPE_CHANGEMENT_COEFF"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_POSITION = "TYPE_CHANGEMENT_POSITION"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_HEURESSUPP = "TYPE_CHANGEMENT_HEURESSUPP"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ENTITE = "TYPE_CHANGEMENT_ENTITE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ENTITE_FACTURATION = "TYPE_CHANGEMENT_ENTITE_FACTURATION"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING = "TYPE_REPORTING"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING_VIA_TEL = "TYPE_REPORTING_VIA_TEL"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING_SUR_SITE = "TYPE_REPORTING_SUR_SITE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REPORTING_EN_AGENCE = "TYPE_REPORTING_EN_AGENCE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_CONTRAT = "TYPE_CHANGEMENT_CONTRAT"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_STATUT = "TYPE_CHANGEMENT_STATUT"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_TJM = "TYPE_CHANGEMENT_TJM"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_ODM_PONCTUEL = "TYPE_AJOUT_ODM_PONCTUEL"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AFFECTATION_MISSION = "TYPE_AFFECTATION_MISSION"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_MISSION = "TYPE_CHANGEMENT_MISSION"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_PROLONGEMENT_MISSION = "TYPE_PROLONGEMENT_MISSION"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_ODM_SIGNE = "TYPE_AJOUT_ODM_SIGNE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_CONTRAT_SIGNE = "TYPE_AJOUT_CONTRAT_SIGNE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_RECEPTION_ODM_SIGNE = "TYPE_RECEPTION_ODM_SIGNE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_RECEPTION_CONTRAT_SIGNE = "TYPE_RECEPTION_CONTRAT_SIGNE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_ANNULATION_RECEPTION_ODM_SIGNE = "TYPE_ANNULATION_RECEPTION_ODM_SIGNE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_ANNULATION_RECEPTION_CONTRAT_SIGNE = "TYPE_ANNULATION_RECEPTION_CONTRAT_SIGNE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_AVENANTCONTRAT_SIGNE = "TYPE_AJOUT_AVENANTCONTRAT_SIGNE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_RECEPTION_AVENANTCONTRAT_SIGNE = "TYPE_RECEPTION_AVENANTCONTRAT_SIGNE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_ANNULATION_RECEPTION_AVENANTCONTRAT_SIGNE = "TYPE_ANNULATION_RECEPTION_AVENANTCONTRAT_SIGNE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_AVENANTCONTRAT = "TYPE_AJOUT_AVENANTCONTRAT"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_BASEFACTURATION = "TYPE_CHANGEMENT_BASEFACTURATION"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_BASEFACTURATION = "TYPE_AJOUT_BASEFACTURATION"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_HEURES_SUPP = "TYPE_AJOUT_HEURES_SUPP"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_FLAG_AS_DELETED = "TYPE_FLAG_AS_DELETED"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_TICKETRESTO = "TYPE_AJOUT_TICKETRESTO"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_SUPPRESSION_TICKETRESTO = "TYPE_SUPPRESSION_TICKETRESTO"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_FAUX_DEPART = "TYPE_FAUX_DEPART"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ADRESSETRAVAIL = "TYPE_CHANGEMENT_ADRESSETRAVAIL"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_INTERCONTRAT = "TYPE_CHANGEMENT_ETAT_TO_INTERCONTRAT"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_HORSEFFECTIF = "TYPE_CHANGEMENT_ETAT_TO_HORSEFFECTIF"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_ETAT_TO_ENMISSION = "TYPE_CHANGEMENT_ETAT_TO_ENMISSION"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_AJOUT_MACHINE = "TYPE_AJOUT_MACHINE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_SUPPRESSION_MACHINE = "TYPE_SUPPRESSION_MACHINE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_CHANGEMENT_PERSONNEL_DELEGATION_ADMINISTRATIVE = "TYPE_CHANGEMENT_PERSONNEL_DELEGATION_ADMINISTRATIVE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_REINITIALISATION_EMBAUCHE = "TYPE_ANNULATION_EDTION_DEMANDE_EMBAUCHE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_PE_DEMANDERENOUVELLEMENT = "TYPE_PE_DEMANDERENOUVELLEMENT"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_PE_CONFIRMATIONENOUVELLEMENT = "TYPE_PE_CONFIRMATIONENOUVELLEMENT"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_PE_PROPOSITION_DATE_RUPTURE_ANTICIPEE = "TYPE_PE_PROPOSITION_DATE_RUPTURE_ANTICIPEE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_PE_DEMANDE_RUPTURE_ANTICIPEE = "TYPE_PE_DEMANDE_RUPTURE_ANTICIPEE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_IGNORER_ENVOI_DOCS_EMBAUCHE = "TYPE_IGNORER_ENVOI_DOCS_EMBAUCHE"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_DEMANDER_INFO_PRE_EMBAUCHE_ENVOI_MAIL = "TYPE_DEMANDER_INFO_PRE_EMBAUCHE_ENVOI_MAIL"
export const CONST_CONSULTANT_SUIVI_GENERAL_TYPE_RE_DEMANDER_INFO_PRE_EMBAUCHE_ENVOI_MAIL = "TYPE_RE_DEMANDER_INFO_PRE_EMBAUCHE_ENVOI_MAIL"

/**************************************************************/
/**************************************************************/
/*                    END API-CORE CONSTANTS                  */
/**************************************************************/
/**************************************************************/

/* REGEX */
export const REGEXP_PHONE = /^(\d{2} \d{2} \d{2} \d{2} \d{2}|\d{2}\d{2}\d{2}\d{2}\d{2})$/
export const REGEXP_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/* MIME TYPES */
export const CONST_MIME_APPLICATION_JSON = "application/json"
export const CONST_MIME_APPLICATION_XML = "application/xml"
export const CONST_MIME_APPLICATION_PDF = "application/pdf"
export const CONST_MIME_APPLICATION_DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
export const CONST_MIME_APPLICATION_DOC = "application/msword"
export const CONST_MIME_APPLICATION_GOOGLE_DOC = "application/vnd.google-apps.document"
export const CONST_MIME_APPLICATION_ODT = "application/vnd.oasis.opendocument.text"
export const CONST_MIME_APPLICATION_XLS = "application/vnd.ms-excel"
export const CONST_MIME_APPLICATION_XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
export const CONST_MIME_APPLICATION_ODS = "application/vnd.oasis.opendocument.spreadsheet"
export const CONST_MIME_APPLICATION_GOOGLE_SPREADSHEET = "application/vnd.google-apps.spreadsheet"
export const CONST_MIME_APPLICATION_PPT = "application/vnd.ms-powerpoint"
export const CONST_MIME_APPLICATION_PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation"
export const CONST_MIME_APPLICATION_ODP = "application/vnd.oasis.opendocument.presentation"
export const CONST_MIME_APPLICATION_GOOGLE_PRESENTATION = "application/vnd.google-apps.presentation"
export const CONST_MIME_IMAGE_JPEG = "image/jpeg"
export const CONST_MIME_IMAGE_JPG = "image/jpg"
export const CONST_MIME_IMAGE_PNG = "image/png"

/* TODO: Consider renaming with a better name */
export const CONST_MOIS_JANVIER = "01"
export const CONST_MOIS_FEVRIER = "02"
export const CONST_MOIS_MARS = "03"
export const CONST_MOIS_AVRIL = "04"
export const CONST_MOIS_MAI = "05"
export const CONST_MOIS_JUIN = "06"
export const CONST_MOIS_JUILLET = "07"
export const CONST_MOIS_AOUT = "08"
export const CONST_MOIS_SEPTEMBRE = "09"
export const CONST_MOIS_OCTOBRE = "10"
export const CONST_MOIS_NOVEMBRE = "11"
export const CONST_MOIS_DECEMBRE = "12"

/* UTILIZED FOR SELECT2 CHOICES. TODO: Consider renaming with a better name */
export const CONST_MOIS_LIST = [
     {
          label: "Janvier",
          value: CONST_MOIS_JANVIER,
     },
     {
          label: "Fevrier",
          value: CONST_MOIS_FEVRIER,
     },
     {
          label: "Mars",
          value: CONST_MOIS_MARS,
     },
     {
          label: "Avril",
          value: CONST_MOIS_AVRIL,
     },
     {
          label: "Mai",
          value: CONST_MOIS_MAI,
     },
     {
          label: "Juin",
          value: CONST_MOIS_JUIN,
     },
     {
          label: "Juillet",
          value: CONST_MOIS_JUILLET,
     },
     {
          label: "Aout",
          value: CONST_MOIS_AOUT,
     },
     {
          label: "Septembre",
          value: CONST_MOIS_SEPTEMBRE,
     },
     {
          label: "Octobre",
          value: CONST_MOIS_OCTOBRE,
     },
     {
          label: "Novembre",
          value: CONST_MOIS_NOVEMBRE,
     },
     {
          label: "Decembre",
          value: CONST_MOIS_DECEMBRE,
     },
]

export const CONST_CIVILITES_OPTIONS_FOR_SELECT2 = [
     {
          label: "Monsieur",
          value: CONST_CIVILITE_MR,
     },
     {
          label: "Madame",
          value: CONST_CIVILITE_MME,
     },
     {
          label: "Non renseignée",
          value: CONST_CIVILITE_NON_RENSEIGNEE,
     },
]

export const CONST_OUI_NON_FOR_SELECT2 = [
     {
          label: "Oui",
          value: true,
     },
     {
          label: "Non",
          value: false,
     },
]

export const CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2 = [
     {
          value: CONST_NDF_DETAILS_CATEGORIE_PARKING,
          label: "Parking",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_CARBURANT,
          label: "Carburant",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_PEAGE,
          label: "Péage",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_TRANSPORT_EN_COMMUN,
          label: "Transport en commun",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_AVION,
          label: "Avion",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_TRAIN,
          label: "Train",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_TAXI,
          label: "Taxi",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_HOTEL,
          label: "Hôtel",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_KM,
          label: "Frais kilométriques",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
          label: "Repas midi",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_REPAS_SOIR,
          label: "Repas soir",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_DIVERS,
          label: "Divers",
     },
]

export const CONST_PERSONNEL_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2 = [
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_KM,
          label: "Frais kilométriques",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_CARBURANT,
          label: "Carburant",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_PEAGE,
          label: "Péage",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_PARKING,
          label: "Parking",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_AVION,
          label: "Avion",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_TRAIN,
          label: "Train",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_TAXI,
          label: "Taxi",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_TRANSPORT_EN_COMMUN,
          label: "Transport en commun",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_HOTEL,
          label: "Hôtel",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_DIVERS,
          label: "Divers",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
          label: "Repas Midi",
     },
     {
          value: CONST_PERSONNEL_NDF_DETAILS_CATEGORIE_REPAS_SOIR,
          label: "Repas Soir",
     },
]

export const CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2 = [
     {
          label: "Passport / CNI",
          value: CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI,
     },
     {
          label: "Titre de séjour",
          value: CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR,
     },
     {
          label: "Attestation SS",
          value: CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS,
     },
     {
          label: "RIB",
          value: CONST_CONSULTANT_DOCUMENT_TYPE_RIB,
     },
     {
          label: "Permis de conduire",
          value: CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE,
     },
     {
          label: "Diplôme",
          value: CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME,
     },
     {
          label: "Autre",
          value: CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES,
     },
]

export const CONST_NDF_TYPES_FOR_SELECT2 = [
     {
          label: "Congés payés",
          value: CONST_DDC_TYPE_CONGES_PAYES,
     },
     {
          label: "Congés sans solde",
          value: CONST_DDC_TYPE_CONGES_SANS_SOLDE,
     },
     {
          label: "Repos compensateur",
          value: CONST_DDC_TYPE_REPOS_COMPENSATEUR,
     },
     {
          label: "Absence autorisée",
          value: CONST_DDC_TYPE_ABSENCE_AUTORISEE,
     },
     {
          label: "Absence exceptionnelle",
          value: CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
     },
]

export const CONST_CONTRATS_FOR_SELECT2 = [
     {
          label: "CDI C",
          value: CONST_CONSULTANT_CONTRAT_CDIC,
     },
     {
          label: "CDI",
          value: CONST_CONSULTANT_CONTRAT_CDI,
     },
     {
          label: "CDD",
          value: CONST_CONSULTANT_CONTRAT_CDD,
     },
     {
          label: "Sous-traitance",
          value: CONST_CONSULTANT_CONTRAT_ST,
     },
]

export const CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2 = [
     {
          label: "Immédiate",
          value: CONST_DISPONIBILITE_IMMEDIATE,
     },
     {
          label: "Préavis en cours",
          value: CONST_DISPONIBILITE_PREAVIS_EN_COURS,
     },
     {
          label: "Préavis de 2 semaines",
          value: CONST_DISPONIBILITE_PREAVIS_2_SEMAINES,
     },
     {
          label: "Préavis de 1 mois",
          value: CONST_DISPONIBILITE_PREAVIS_1_MOIS,
     },
     {
          label: "Préavis de 2 mois",
          value: CONST_DISPONIBILITE_PREAVIS_2_MOIS,
     },
     {
          label: "Préavis de 3 mois",
          value: CONST_DISPONIBILITE_PREAVIS_3_MOIS,
     },
     {
          label: "Date exacte",
          value: CONST_DISPONIBILITE_DATE_EXACTE,
     },
]

export const CONST_CANDIDAT_TYPES_FOR_SELECT2 = [
     {
          label: "Postulant à une annonce",
          value: CONST_CANDIDAT_TYPE_POSTULANT,
     },
     {
          label: "Candidature spontanée",
          value: CONST_CANDIDAT_TYPE_CS,
     },
     {
          label: "Saisi manuellement",
          value: CONST_CANDIDAT_TYPE_SAISI,
     },
]

export const CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2 = [
     {
          label: "Ingénieur",
          value: CONST_NIVEAU_ETUDES_INGE,
     },
     {
          label: "Technicien",
          value: CONST_NIVEAU_ETUDES_TECHNICIEN,
     },
]

export const CONST_CANDIDAT_EXPERIENCES_OPTIONS_FOR_SELECT2 = [
     {
          label: "0-1 an",
          value: CONST_CANDIDAT_EXPERIENCE_0A1_ANS,
     },
     {
          label: "1-2 ans",
          value: CONST_CANDIDAT_EXPERIENCE_1A2_ANS,
     },
     {
          label: "2-5 ans",
          value: CONST_CANDIDAT_EXPERIENCE_2A5_ANS,
     },
     {
          label: "5-7 ans",
          value: CONST_CANDIDAT_EXPERIENCE_5A7_ANS,
     },
     {
          label: "7-10 ans",
          value: CONST_CANDIDAT_EXPERIENCE_7A10_ANS,
     },
     {
          label: "10-15 ans",
          value: CONST_CANDIDAT_EXPERIENCE_10A15_ANS,
     },
     {
          label: "+ 15 ans",
          value: CONST_CANDIDAT_EXPERIENCE_15PLUS_ANS,
     },
]

export const CONST_CANDIDAT_DOCUMENT_TYPES_FOR_SELECT2 = [
     {
          label: "Curriculum",
          value: CONST_CANDIDAT_DOCUMENT_TYPE_CV,
     },
]

export const CONST_MOBILITE_OPTIONS_FOR_SELECT2 = [
     {
          label: "Par ville",
          value: CONST_MOBILITE_PAR_VILLE,
     },
     {
          label: "Départemental",
          value: CONST_MOBILITE_DEPARTEMENTAL,
     },
     {
          label: "Régional",
          value: CONST_MOBILITE_REGIONAL,
     },
     {
          label: "Île de France",
          value: CONST_MOBILITE_IDF,
     },
     {
          label: "Province",
          value: CONST_MOBILITE_FRANCE_HORS_IDF,
     },
     {
          label: "International",
          value: CONST_MOBILITE_INTERNATIONAL,
     },
     {
          label: "France",
          value: CONST_MOBILITE_FRANCE,
     },
]

export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_OPERATEURS_LOGIQUE_OPTIONS_FOR_SELECT2 = [
     {
          label: "ET",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_OPERATEUR_LOGIQUE_ET,
     },
     {
          label: "OU",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_OPERATEUR_LOGIQUE_OU,
     },
]

export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATIONS_OPTIONS_FOR_SELECT2 = [
     {
          label: "Candidats que je n'ai pas vu",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATION_PASVUPARMOI,
     },
     {
          label: "Candidats vus par moi et ma team",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATION_VUPARMOIETPARTEAM,
     },
     {
          label: "Candidats non vus par moi ou ma team",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_VISUALISATION_NONVUPARMOIETNONVUPARTEAM,
     },
]

export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORD_TARGETS_OPTIONS_FOR_SELECT2 = [
     {
          label: "CV uniquement",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_CV,
     },
     {
          label: "nom, mail ou téléphone",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_NOM_MAIL_TEL,
     },
     {
          label: "suivi",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_KEYWORDTARGET_SUIVI,
     },
]
export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVIS_OPTIONS_FOR_SELECT2 = [
     {
          label: "candidats avec mon suivi",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVI_AVEC_MON_SUIVI,
     },
     {
          label: "candidats sans mon suivi",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVI_SANS_MON_SUIVI,
     },
     {
          label: "candidats sans mon suivi ou celui de mes co-équipiers",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVI_SANS_MON_SUIVI_NI_CELUI_COEQUIPIERS,
     },
     {
          label: "candidats avec mon suivi ou celui de mes co-équipiers",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_SUIVI_AVEC_MON_SUIVI_OU_CELUI_COEQUIPIERS,
     },
]

export const CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEURS_CV_OPTIONS_FOR_SELECT2 = [
     {
          label: "Candidature de moins de 15 jours",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_15_JOURS,
     },
     {
          label: "Candidature de moins de 1 mois",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_1_MOIS,
     },
     {
          label: "Candidature de moins de 2 mois",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_2_MOIS,
     },
     {
          label: "Candidature de moins de 6 mois",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_6_MOIS,
     },
     {
          label: "Candidature de moins de 12 mois",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_MOINS_12_MOIS,
     },
     {
          label: "Toutes les candidatures",
          value: CONST_PERSONNEL_RECHERCHE_CANDIDAT_HISTORIQUE_SAUVEGARDE_FRAICHEUR_CV_TOUTES,
     },
]

export const CONST_ANNONCE_ETATS_OPTIONS_FOR_SELECT2 = [
     {
          label: "Brouillon",
          value: CONST_ANNONCE_ETAT_ON_CREATION,
     },
     {
          label: "En ligne",
          value: CONST_ANNONCE_ETAT_ACTIVE,
     },
     {
          label: "Archivée",
          value: CONST_ANNONCE_ETAT_ARCHIVED,
     },
]

export const CONST_CONSULTANT_STATUTS_OPTIONS_FOR_SELECT2 = [
     {
          label: "ETAM",
          value: CONST_CONSULTANT_STATUT_ETAM,
     },
     {
          label: "Agent de maîtrise",
          value: CONST_CONSULTANT_STATUT_AGENT_MAITRISE,
     },
     {
          label: "Cadre",
          value: CONST_CONSULTANT_STATUT_CADRE,
     },
     {
          label: "Porté",
          value: CONST_CONSULTANT_STATUT_ST_PORTAGE,
     },
     {
          label: "Freelance / Société de service",
          value: CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     },
]

export const CONST_PERSONNEL_STATUTS_OPTIONS_FOR_SELECT2 = [
     {
          label: "ETAM",
          value: CONST_PERSONNEL_STATUT_STATUT_ETAM,
     },
     {
          label: "Agent de maîtrise",
          value: CONST_PERSONNEL_STATUT_STATUT_AGENT_MAITRISE,
     },
     {
          label: "Cadre",
          value: CONST_PERSONNEL_STATUT_STATUT_CADRE,
     },
     {
          label: "Non salarié",
          value: CONST_PERSONNEL_STATUT_STATUT_NON_SALARIE,
     },
]

export const CONST_PERSONNEL_CONTRATS_OPTIONS_FOR_SELECT2 = [
     {
          label: "CDI",
          value: CONST_PERSONNEL_CONTRAT_CDI,
     },
     {
          label: "CDD",
          value: CONST_PERSONNEL_CONTRAT_CDD,
     },
     {
          label: "Contrat non salarié",
          value: CONST_PERSONNEL_CONTRAT_NON_SALARIE,
     },
]

export const CONST_PERSONNEL_DDC_TYPES_FOR_SELECT2 = [
     { label: "Congés payés", value: CONST_PERSONNEL_DDC_TYPE_CONGES_PAYES },
     { label: "Congés sans solde", value: CONST_PERSONNEL_DDC_TYPE_CONGES_SANS_SOLDE },
     { label: "Repos compensateur", value: CONST_PERSONNEL_DDC_TYPE_REPOS_COMPENSATEUR },
     { label: "Absence autorisée", value: CONST_PERSONNEL_DDC_TYPE_ABSENCE_AUTORISEE },
     { label: "Absence exceptionnelle", value: CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE },
     { label: "RTT", value: CONST_PERSONNEL_DDC_TYPE_ABSENCE_RTT },
]

export const CONST_PERSONNEL_CRA_DETAILS_ABSENCE_TYPES_FOR_SELECT2 = [
     { label: "Congés payé", value: CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_CPA },
     { label: "RTT", value: CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_RTT },
     { label: "Absence autorisée", value: CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_AUTORISEE },
     { label: "Férié", value: CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_FERIES },
     { label: "Maladie", value: CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_CMA },
     { label: "Congés exceptionnels", value: CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_CEX },
     { label: "Repos compensateur", value: CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_REPOS_COMPENSATEUR },
     { label: "Stage de formation", value: CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_SFP },
     { label: "Congés sans solde", value: CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_CSS },
]

export const CONST_PERSONNEL_DDC_SOUS_TYPES_FOR_SELECT2 = [
     { label: "Mariage / PACS", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_PACS },
     { label: "Mariage d'un enfant", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT },
     { label: "Naissance enfant", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT },
     { label: "Décès d'un enfant de moins de 25 ans", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS },
     { label: "Décès d'un enfant de plus de 25 ans", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS },
     { label: "Décès du conjoint ou concubin ou partenaire de PACS", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS },
     { label: "Décès parents", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENT },
     { label: "Décès des parents du conjoint lié par le mariage", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE },
     { label: "Décès frère/soeur", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR },
     {
          label: "Décès beau-père/belle-mère (du conjoint marié ou pacsé)",
          value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS,
     },
     {
          label: "Annonce de la survenue d'un handicap ou maladie grave chez un enfant",
          value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT,
     },
     { label: "Décès grands-parents/ arrière-grands-parents", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT },
     { label: "Autre", value: CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_AUTRE },
]

export const CONST_FOURNISSEUR_FORMES_JURIDIQUE_OPTIONS_FOR_SELECT2 = [
     {
          label: "Auto-entrepreneur",
          value: CONST_FOURNISSEUR_FORME_JURIDIQUE_AUTOENTREPREURE,
     },
     {
          label: "SAS",
          value: CONST_FOURNISSEUR_FORME_JURIDIQUE_SAS,
     },
     {
          label: "SASU",
          value: CONST_FOURNISSEUR_FORME_JURIDIQUE_SASU,
     },
     {
          label: "SARL",
          value: CONST_FOURNISSEUR_FORME_JURIDIQUE_SARL,
     },
     {
          label: "EURL",
          value: CONST_FOURNISSEUR_FORME_JURIDIQUE_EURL,
     },
     {
          label: "BIC/BNC",
          value: CONST_FOURNISSEUR_FORME_JURIDIQUE_BICBNC,
     },
]

export const CONST_FOURNISSEUR_TYPES_OPTIONS_FOR_SELECT2 = [
     {
          label: "Société de service",
          value: CONST_FOURNISSEUR_TYPE_SOCIETE_SERVICE,
     },
     {
          label: "Société de portage",
          value: CONST_FOURNISSEUR_TYPE_SOCIETE_PORTAGE,
     },
]

export const CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPES_OPTIONS_FOR_SELECT2 = [
     {
          label: "Principale",
          value: CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_MAIN,
     },
     {
          label: "TT",
          value: CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_TT,
     },
     {
          label: "École",
          value: CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_ALTERNANCE,
     },
]

/* LIBS DEFAULT CUSTOM CONFIGURATION */
export const CONST_DEFAULT_TOAST_OPTIONS: ToastOptions = {
     position: "bottom-right",
     autoClose: 4000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     transition: Slide,
     draggable: true,
     theme: "light",
}
