import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import { useQuery, useQueryClient } from "react-query"
import { AxiosError } from "axios"
import { IPersonnelDdcModel } from "@common-models/*"
import MyAlert from "@common-utils/MyAlert"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import PersonnelDdcListingItem from "./_PersonnelDdcListingItem"
import { toast } from "react-toastify"
import MyModal from "@common-utils/MyModal"
import ConnectedPersonnelEditDdcForm from "../../../mes-activites/ddc/edit/_ConnectedPersonnelEditDdcForm"
import PersonnelDdcSuivi from "../suivi/_PersonnelDdcSuivi"
import { getPersonnelDdcRequest } from "../../../core/_requests"
import { IGetPersonnelDdcRequestModel } from "../../../core/_models"

const PersonnelDdcListingContext = createContext<IPersonnelDdcListingContextPropsModel>({} as IPersonnelDdcListingContextPropsModel)
export const usePersonnelDdcListingContext = () => useContext(PersonnelDdcListingContext)

export const REACT_QUERY_KEY_GET_PERSONNEL_DDC_LISTING = "REACT_QUERY_KEY_GET_PERSONNEL_DDC_LISTING"
const PersonnelDdcListingForOne = ({ personnel_ID, filtres = {}, editionMode = true, setDataLoaded }: IPersonnelDdcListingPropsModel) => {
     const queryClient = useQueryClient()

     const [ddcEditionInModal, setDdcEditionInModal] = useState<IPersonnelDdcModel>()
     const [ddcSuiviInModal, setDdcSuiviInModal] = useState<IPersonnelDdcModel>()

     const getDdcListingQuery = useQuery<IPersonnelDdcModel[], AxiosError>(REACT_QUERY_KEY_GET_PERSONNEL_DDC_LISTING, () =>
          getPersonnelDdcRequest(personnel_ID, filtres).then(r => {
               if (setDataLoaded) setDataLoaded({ isLoaded: true, totalData: r.data.length })
               return r.data
          })
     )

     function handleEditDdcCallback(newItem: IPersonnelDdcModel) {
          queryClient.setQueryData<IPersonnelDdcModel[] | undefined>(REACT_QUERY_KEY_GET_PERSONNEL_DDC_LISTING, (prev: IPersonnelDdcModel[] | undefined) => {
               if (prev) {
                    const index = prev.findIndex(item => item.id === ddcEditionInModal?.id)
                    if (index >= 0) {
                         prev[index] = {
                              ...prev[index],
                              ...newItem,
                         }
                    }
               }

               return prev
          })
          setDdcEditionInModal(undefined)
          toast.success("Votre arrêt maladie a bien été enregistré.")
          toast.info(
               "Merci de vérifier, lors de l'étape de Compte Rendu d'Activité (CRA), si les heures de congés ainsi que celles de travail pour la période concernée par votre demande sont corrects.",
               {
                    autoClose: false,
               }
          )
     }

     return (
          <>
               <PersonnelDdcListingContext.Provider
                    value={{
                         filtres,
                         setDdcEditionInModal,
                         setDdcSuiviInModal,
                         editionMode,
                         REACT_QUERY_KEY_GET_CONNECTED_USER_DDC_LISTING: REACT_QUERY_KEY_GET_PERSONNEL_DDC_LISTING,
                    }}
               >
                    {/* Is fetching */}
                    {getDdcListingQuery.isFetching && (
                         <div className={"text-center"}>
                              <MySimpleSpinner size={"xl"} />
                         </div>
                    )}

                    {/* Is error */}
                    {!getDdcListingQuery.isFetching && getDdcListingQuery.isError && (
                         <MyAlert type={"danger"}>
                              <div className={"d-flex align-items-center"}>
                                   <div className={"me-2"}>{getDdcListingQuery.error?.response?.data?.detail}</div>
                                   <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => getDdcListingQuery.refetch()}>
                                        Recharger
                                   </button>
                              </div>
                         </MyAlert>
                    )}

                    {/* Without data */}
                    {!getDdcListingQuery.isFetching && !getDdcListingQuery.isError && getDdcListingQuery.data!.length === 0 && (
                         <MyAlert type={"primary"}>Aucune DDC n'a été déclarée</MyAlert>
                    )}

                    {/* With data */}
                    {!getDdcListingQuery.isFetching && !getDdcListingQuery.isError && getDdcListingQuery.data && getDdcListingQuery.data!.length > 0 && (
                         <>
                              <div className="row">
                                   {getDdcListingQuery.data.map((item, key) => (
                                        <div className={`col-md-6 mb-6`} key={key}>
                                             <PersonnelDdcListingItem item={item} />
                                        </div>
                                   ))}
                              </div>
                         </>
                    )}
               </PersonnelDdcListingContext.Provider>

               {/* Edition in modal */}
               {ddcEditionInModal && (
                    <MyModal show={true} handleClose={() => setDdcEditionInModal(undefined)} title={<>{ddcEditionInModal.typeLabel}</>} size={"lg"}>
                         <ConnectedPersonnelEditDdcForm
                              ddc={ddcEditionInModal}
                              du={filtres?.du}
                              au={filtres?.au}
                              duOrAuWithThisMonthAndYear={filtres?.duOrAuWithThisMonthAndYear}
                              callback={handleEditDdcCallback}
                         />
                    </MyModal>
               )}

               {/* Modal with suivi */}
               {ddcSuiviInModal && (
                    <MyModal title={<span>Suivi de la déclaration de {ddcSuiviInModal!.personnel?.prenomNom}</span>} show={true} handleClose={() => setDdcSuiviInModal(undefined)}>
                         <PersonnelDdcSuivi ddc={ddcSuiviInModal} />
                    </MyModal>
               )}
          </>
     )
}

interface IPersonnelDdcListingPropsModel {
     personnel_ID: number
     editionMode?: boolean
     filtres?: IGetPersonnelDdcRequestModel
     setDataLoaded?: React.Dispatch<React.SetStateAction<{ isLoaded: boolean; totalData: number } | undefined>>
}

interface IPersonnelDdcListingContextPropsModel {
     editionMode?: boolean
     filtres?: IGetPersonnelDdcRequestModel
     setDdcEditionInModal: Dispatch<SetStateAction<IPersonnelDdcModel | undefined>>
     setDdcSuiviInModal: Dispatch<SetStateAction<IPersonnelDdcModel | undefined>>
     REACT_QUERY_KEY_GET_CONNECTED_USER_DDC_LISTING: string
}

interface IConnectedUserMaladiesListingWrapperContextPropsModel {
     setCreateInModal: Dispatch<SetStateAction<boolean>>
     createInModal: boolean
}

export default PersonnelDdcListingForOne
