import React from "react"
import { AxiosError } from "axios"
import * as Yup from "yup"
import { Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import { IHttpErrorResponseModel, IPersonnelDdcModel } from "@common-models/*"
import {
     CONST_CIVILITE_MR,
     CONST_ENTITE_CODE_GS,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
     CONST_MIME_APPLICATION_PDF,
     CONST_MIME_IMAGE_JPEG,
     CONST_MIME_IMAGE_JPG,
     CONST_MIME_IMAGE_PNG,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_AUTRE,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENT,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_PACS,
     CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT,
     CONST_PERSONNEL_DDC_SOUS_TYPES_FOR_SELECT2,
     CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
     CONST_PERSONNEL_DDC_TYPE_ABSENCE_RTT,
     CONST_PERSONNEL_DDC_TYPES_FOR_SELECT2,
} from "@common-constants/*"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import MyDateDayMonthYearField from "@common-utils/fields/MyDateDayMonthYearField"
import { editConnectedUserDdcRequest } from "../core/_requests"
import { useAuth } from "../../../../../AppContext"
import MySelectField from "@common-utils/fields/MySelectField"
import moment from "moment"
import MyFileField from "@common-utils/fields/MyFileField"
import MyTextareaField from "@common-utils/fields/MyTextareaField"
import MyCheckBoxField from "@common-utils/fields/MyCheckBoxField"
import { IEditConnectedUserDdcRequestModel } from "../core/_models"

const ConnectedPersonnelEditDdcForm = ({ ddc, duOrAuWithThisMonthAndYear, du, au, callback = () => {} }: IConnectedUserCreateDdcFormPropsModel) => {
     const { currentUser } = useAuth()

     // Schema
     const schema = Yup.object().shape({
          du: Yup.string().label(`"Date de début"`).required().nullable(),
          au: Yup.string().label(`"Date de fin"`).required().nullable(),
          type: Yup.string().label(`"Type"`).required().nullable(),
          sousType: Yup.string()
               .label(`"Sous type"`)
               .when("type", {
                    is: CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
                    then: schema => schema.required(),
               })
               .nullable(),
          motif: Yup.string()
               .label(`"Motif"`)
               .when("type", {
                    is: CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE,
                    then: schema => schema.required(),
               })
               .nullable(),
          justificatif: Yup.mixed()
               .nullable()
               .label(`"Justificatif"`)
               .when("voulezVousChangerLaPieceJointe", {
                    is: true,
                    then: schema => schema.required(),
               })
               .test("fileFormat", "Seuls les formats JPEG, PNG ou PDF sont acceptés", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return [CONST_MIME_IMAGE_PNG, CONST_MIME_IMAGE_JPEG, CONST_MIME_IMAGE_JPG, CONST_MIME_APPLICATION_PDF].includes(value.type)
               })
               .test("fileSize", "Le document ne doit pas dépasser le 10MB", (value: any) => {
                    if (!value) return true // If no file is provided, skip the test
                    return value.size <= 10 * 1024 * 1024
               }),
     })

     // Init values
     const initialValues: IEditConnectedUserDdcRequestModel = {
          du: ddc.du?.format("YYYY-MM-DD"),
          au: ddc.au?.format("YYYY-MM-DD"),
          type: ddc.type,
          sousType: ddc.sousType,
          motif: ddc.motif || "",
          voulezVousChangerLaPieceJointe: false,
     }

     // Handle submit
     function handleSubmit(values: IEditConnectedUserDdcRequestModel, helpers: FormikHelpers<IEditConnectedUserDdcRequestModel>) {
          helpers.setStatus(null)

          editConnectedUserDdcRequest(ddc.id as number, values)
               .then(r => {
                    callback(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     // Alert info absence ixciptionnil
     const InfoAccordingToSousCategorieAbsenceExceptionnelle = ({ sousType, gender }: { sousType: string; gender: string }) => {
          let text: string
          switch (sousType) {
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_PACS:
                    text = "4 jours maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT:
                    text = "1 jour maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT:
                    if (gender === CONST_CIVILITE_MR) {
                         text = "3 jours maximum + 4 jours obligatoire du congés paternité"
                         break
                    } else {
                         text = "3 jours maximum"
                         break
                    }
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS:
                    text = "22 jours maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS:
                    text = "5 jours maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS:
                    text = "3 jours maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENT:
                    text = "3 jours maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE:
                    text = "3 jours maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR:
                    text = "3 jours maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS:
                    text = "3 jours maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT:
                    text = "5 jours maximum"
                    break
               case CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT:
                    text = "2 jours maximum"
                    break
               default:
                    text = ""
          }

          return (
               <MyAlert type={"info"}>
                    <span>Vous avez le droit à {text}</span>
               </MyAlert>
          )
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    console.log(helpers.errors)
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}

                              {helpers.values.type === CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE &&
                                   helpers.values.sousType &&
                                   helpers.values.sousType !== CONST_PERSONNEL_DDC_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_AUTRE && (
                                        <div className="mb-4">
                                             <InfoAccordingToSousCategorieAbsenceExceptionnelle sousType={helpers.values.sousType} gender={currentUser!.civilite as string} />
                                        </div>
                                   )}
                              <div className="row pb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez la catégorie</label>

                                   <div className="col-lg-8">
                                        <MySelectField
                                             options={CONST_PERSONNEL_DDC_TYPES_FOR_SELECT2.filter(
                                                  item =>
                                                       item.value !== CONST_PERSONNEL_DDC_TYPE_ABSENCE_RTT ||
                                                       (item.value === CONST_PERSONNEL_DDC_TYPE_ABSENCE_RTT && currentUser!.mainEntite?.code === CONST_ENTITE_CODE_GS)
                                             )}
                                             name={`type`}
                                             isSearchable={false}
                                             value={helpers.values.type}
                                             onChange={value => helpers.setFieldValue(`type`, value)}
                                             isInvalid={!!helpers.errors.type}
                                        />
                                        {helpers.errors.type && <div className={"text-danger"}>{helpers.errors.type}</div>}
                                   </div>
                              </div>

                              {helpers.values.type === CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE && (
                                   <div className="row pb-4">
                                        <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez la sous catégorie</label>

                                        <div className="col-lg-8">
                                             <MySelectField
                                                  options={CONST_PERSONNEL_DDC_SOUS_TYPES_FOR_SELECT2}
                                                  name={`sousType`}
                                                  isSearchable={false}
                                                  value={helpers.values.sousType}
                                                  onChange={value => helpers.setFieldValue(`sousType`, value)}
                                                  isInvalid={!!helpers.errors.sousType}
                                             />
                                             {helpers.errors.sousType && <div className={"text-danger"}>{helpers.errors.sousType}</div>}
                                        </div>
                                   </div>
                              )}

                              <div className="row pb-4">
                                   <label className="col-lg-4 col-form-label fw-bold fs-6 required">Saisissez la période</label>

                                   <div className="col-lg-8">
                                        <div className="row">
                                             <div className="col">
                                                  <MyDateDayMonthYearField
                                                       onChange={date => helpers.setFieldValue("du", date)}
                                                       minDate={
                                                            moment(duOrAuWithThisMonthAndYear)?.startOf("month").format("DD/MM/YYYY") ||
                                                            moment(du)?.startOf("month").format("DD/MM/YYYY")
                                                       }
                                                       maxDate={
                                                            moment(duOrAuWithThisMonthAndYear)?.endOf("month").format("DD/MM/YYYY") ||
                                                            moment(au)?.endOf("month").format("DD/MM/YYYY")
                                                       }
                                                       value={helpers.values.du}
                                                       name={"du"}
                                                       placeholder={"Du (inclus)"}
                                                       isInvalid={!!helpers.errors.du}
                                                  />
                                                  {helpers.errors.du && <div className={"text-danger"}>{helpers.errors.du}</div>}
                                             </div>
                                             <div className="col">
                                                  <MyDateDayMonthYearField
                                                       onChange={date => helpers.setFieldValue("au", date)}
                                                       value={helpers.values.au}
                                                       minDate={helpers.values.du}
                                                       name={"au"}
                                                       placeholder={"Au (inclus)"}
                                                       isInvalid={!!helpers.errors.au}
                                                  />
                                                  {helpers.errors.au && <div className={"text-danger"}>{helpers.errors.au}</div>}
                                             </div>
                                        </div>
                                   </div>
                              </div>

                              {helpers.values.type === CONST_PERSONNEL_DDC_TYPE_ABSENCE_EXCEPTIONNELLE && (
                                   <>
                                        <div className="row pb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez le motif</label>

                                             <div className="col-lg-8">
                                                  <MyTextareaField
                                                       name={"motif"}
                                                       value={helpers.values.motif}
                                                       isInvalid={!!helpers.errors.motif}
                                                       onChange={val => helpers.setFieldValue("motif", val)}
                                                  />
                                                  {helpers.errors.motif && <div className={"text-danger"}>{helpers.errors.motif}</div>}
                                             </div>
                                        </div>
                                        <div className="row pb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6">Voulez-vous remplacer la pièce jointe?</label>

                                             <div className="col-lg-8 d-flex align-items-center">
                                                  <MyCheckBoxField
                                                       name={"voulezVousChangerLaPieceJointe"}
                                                       value={helpers.values.voulezVousChangerLaPieceJointe}
                                                       isInvalid={!!helpers.errors.voulezVousChangerLaPieceJointe}
                                                       onChange={val => helpers.setFieldValue("voulezVousChangerLaPieceJointe", val)}
                                                  />
                                                  {helpers.errors.voulezVousChangerLaPieceJointe && (
                                                       <div className={"text-danger"}>{helpers.errors.voulezVousChangerLaPieceJointe}</div>
                                                  )}
                                             </div>
                                        </div>
                                        {helpers.values.voulezVousChangerLaPieceJointe && (
                                             <div className="row pb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Justificatif</label>

                                                  <div className="col-lg-8">
                                                       <MyFileField
                                                            name={"justificatif"}
                                                            isInvalid={!!helpers.errors.justificatif}
                                                            onChange={value => helpers.setFieldValue("justificatif", value)}
                                                       />
                                                       {helpers.errors.justificatif && <div className={"text-danger"}>{helpers.errors.justificatif}</div>}
                                                  </div>
                                             </div>
                                        )}
                                   </>
                              )}

                              {/* Submit button */}
                              <div className={"d-flex justify-content-end"}>
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? <>Appliquer les modifications</> : <MySimpleSpinner size={"sm"} />}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

interface IConnectedUserCreateDdcFormPropsModel {
     ddc: IPersonnelDdcModel
     callback?: (ddc: IPersonnelDdcModel) => void
     duOrAuWithThisMonthAndYear?: string
     du?: string
     au?: string
}

export default ConnectedPersonnelEditDdcForm
