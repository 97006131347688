import React, { createContext, Dispatch, SetStateAction, useContext, useState } from "react"
import { IConsultantDeclarationMensuelleModel } from "@common-models/*"
import { ConsultantDeclarationDetailsNavigationHeader } from "./_ConsultantDeclarationDetails_NavigationHeader"
import ConsultantDeclarationDetailsDDC from "./_ConsultantDeclarationDetails_DDC"
import ConsultantDeclarationDetailsCra from "./_ConsultantDeclarationDetails_CRA"
import ConsultantDeclarationDetailsNDF from "./_ConsultantDeclarationDetails_NDF"
import ConsultantDeclarationDetailsTicketsRestaurant from "./_ConsultantDeclarationDetails_TR"
import ConsultantDeclarationDetailsArrets from "./_ConsultantDeclarationDetails_arrets"
import MyAlert from "@common-utils/MyAlert"
import { toast } from "react-toastify"
import {
     CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
     CONST_DECLARATION_SUIVI_TYPE_DEMANDE_REGUL_PAR_CONSULTANT,
     CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE,
} from "@common-constants/*"
import { useAuth } from "../../../../../AppContext"
import swal from "sweetalert2"
import {
     addSuiviDeclarationDemandeRectificationParResponsable,
     addSuiviDeclarationDemandeRectificationParSiege,
     addSuiviDeclarationRejetDemandeRegulParRespRequest,
     addSuiviDeclarationValidationDemandeRegulParRespRequest,
     addSuiviDeclarationValidationResponsable,
     addSuiviDeclarationValidationSiege,
} from "../core/_requests"

const ConsultantDeclarationDetails = ({
     declaration,
     handleDemandeRectificationCallback = () => {},
     handleValidationCallback = () => {},
     handleValidationDemandeRegulCallback = () => {},
     handleRejetDemandeRegulCallback = () => {},
     defaultStep = "arrets",
}: IConsultantDeclarationDetailsPropsModel) => {
     const [navigationStep, setNavigationStep] = useState<"arrets" | "ddc" | "cra" | "ndf" | "tr">(defaultStep)
     const { currentUser } = useAuth()

     const containerClassAccordingToStep = (() => {
          if (navigationStep === "arrets") return "w-100 w-lg-75 w-xxl-65"
          if (navigationStep === "ddc") return "w-100 w-lg-75 w-xxl-65"
          if (navigationStep === "cra") return "w-100"
          if (navigationStep === "ndf") return "w-100 w-lg-90 w-xxl-65"
          if (navigationStep === "tr") return "w-100"

          return ""
     })()

     // Handles demande rectification resp ou siège
     function handleDemandeRectification() {
          const request =
               currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? addSuiviDeclarationDemandeRectificationParSiege : addSuiviDeclarationDemandeRectificationParResponsable

          swal.fire({
               icon: "warning",
               title: `Avant de procéder, merci de saisir un commentaire`,
               input: "textarea",
               inputPlaceholder: "Commentaire ...",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Envoyer la demande",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci d'ajouter un commentaire") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return request(declaration.id as number, commentaire)
                         .then(r => {
                              toast.info("Votre demande a bien été envoyée")
                              setNavigationStep("arrets")
                              handleDemandeRectificationCallback && handleDemandeRectificationCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles validation responsable ou siège
     function handleValidation() {
          const request = currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF" ? addSuiviDeclarationValidationSiege : addSuiviDeclarationValidationResponsable

          swal.fire({
               icon: "info",
               title:
                    declaration.etat?.type === CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE
                         ? `Vous êtes sur le point de retourner la déclaration au siège`
                         : `Vous êtes sur le point de valider cette déclaration`,
               text:
                    declaration.etat?.type === CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE
                         ? "Cela signifie que la déclaration ne contient pas d'erreurs. Voulez-vous continuer?"
                         : `Voulez-vous continuer?`,
               input: "textarea",
               inputPlaceholder:
                    declaration.etat?.type === CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE ? "Précisez la raison de ce retour" : "Commentaire (optionnel)",
               inputAttributes: {
                    required: declaration.etat?.type === CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE ? "true" : "false",
               },
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         declaration.etat?.type === CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE && value === ""
                              ? resolve("Merci de préciser la raison de ce retour")
                              : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return request(declaration.id as number, commentaire)
                         .then(r => {
                              toast.success(
                                   declaration.etat?.type === CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE
                                        ? "La déclaration a été retournée au siège."
                                        : "Vous avez bien validé cette déclaration."
                              )
                              setNavigationStep("cra")
                              handleValidationCallback && handleValidationCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles validation demande régul
     function handleValidationDemandeRegul() {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point de valider la demande de régularisation de ${declaration.consultant?.prenomNom}`,
               text: `Voulez-vous continuer?`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return addSuiviDeclarationValidationDemandeRegulParRespRequest(declaration.id as number)
                         .then(r => {
                              toast.success("Vous avez bien validé cette demande.")
                              setNavigationStep("cra")
                              handleValidationDemandeRegulCallback && handleValidationDemandeRegulCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     // Handles rejet demande régul
     function handleRejetDemandeRegul() {
          swal.fire({
               icon: "info",
               title: `Vous êtes sur le point de rejeter la demande de régularisation de ${declaration.consultant?.prenomNom}`,
               text: `Voulez-vous continuer?`,
               showCancelButton: true,
               confirmButtonText: "Confirmer",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               input: "textarea",
               inputPlaceholder: "Merci de préciser la raison de ce rejet",
               inputAttributes: {
                    required: "true",
               },
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Merci de préciser la raison de ce rejet") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return addSuiviDeclarationRejetDemandeRegulParRespRequest(declaration.id as number, commentaire)
                         .then(r => {
                              toast.success("Vous avez rejeté cette demande.")
                              setNavigationStep("cra")
                              handleRejetDemandeRegulCallback && handleRejetDemandeRegulCallback(r.data)
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     function isActionDemandeRectifAvailable() {
          if (currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF") {
               return [CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE].includes(declaration.etat?.type as string)
          } else {
               return [
                    CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
                    CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE,
                    CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                    CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
                    CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
               ].includes(declaration.etat?.type as string)
          }
     }

     function isActionValidationAvailable() {
          // Chargé de recrutement n'a pas le droit de valider
          if (currentUser?.roleGroup === "ROLE_GROUP_RECRUTEUR") return false

          if (currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF") {
               return [CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE].includes(declaration.etat?.type as string)
          } else {
               return [
                    CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
                    CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE,
                    CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                    CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
                    CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
               ].includes(declaration.etat?.type as string)
          }
     }

     function isActionValiderOuRejeterDemandeRegulAvailable() {
          return currentUser?.roleGroup !== "ROLE_GROUP_ADMINISTRATIF" && [CONST_DECLARATION_SUIVI_TYPE_DEMANDE_REGUL_PAR_CONSULTANT].includes(declaration.etat?.type as string)
     }

     return (
          <>
               <div className={"d-flex justify-content-center"}>
                    <div className={containerClassAccordingToStep}>
                         <ConsultantDeclarationDetailsContext.Provider
                              value={{
                                   navigationStep,
                                   setNavigationStep,
                                   handleValidation,
                                   handleDemandeRectification,
                                   handleValidationDemandeRegul,
                                   handleRejetDemandeRegul,
                                   isActionDemandeRectifAvailable: isActionDemandeRectifAvailable(),
                                   isActionValidationAvailable: isActionValidationAvailable(),
                                   isActionValiderOuRejeterDemandeRegulAvailable: isActionValiderOuRejeterDemandeRegulAvailable(),
                              }}
                         >
                              <>
                                   {declaration.etat?.commentaire && (
                                        <MyAlert type={"info"} customIcon={"fas fa-comment-dots"}>
                                             <b className={"text-dark"}>
                                                  {declaration.etat.personnel ? declaration.etat.personnel?.prenomNom : declaration.consultant?.prenomNom}:{" "}
                                             </b>{" "}
                                             {declaration.etat?.commentaire}
                                        </MyAlert>
                                   )}

                                   <ConsultantDeclarationDetailsNavigationHeader />

                                   <div className="separator separator-dashed mb-5" />

                                   <div className="px-15">
                                        {navigationStep === "arrets" && (
                                             <ConsultantDeclarationDetailsArrets
                                                  consultant_id={declaration.consultant!.id as number}
                                                  filtres={{
                                                       duOrAuWithThisMonthAndYear: declaration.mois!.format("YYYY-MM-DD"),
                                                  }}
                                             />
                                        )}
                                        {navigationStep === "ddc" && (
                                             <ConsultantDeclarationDetailsDDC
                                                  consultant_id={declaration.consultant!.id as number}
                                                  filtres={{
                                                       duOrAuWithThisMonthAndYear: declaration.mois!.format("YYYY-MM-DD"),
                                                  }}
                                             />
                                        )}
                                        {navigationStep === "cra" && <ConsultantDeclarationDetailsCra declaration_id={declaration?.id as number} />}
                                        {navigationStep === "ndf" && <ConsultantDeclarationDetailsNDF declaration_id={declaration.id as number} />}
                                        {navigationStep === "tr" && <ConsultantDeclarationDetailsTicketsRestaurant declaration={declaration} />}
                                   </div>
                              </>
                         </ConsultantDeclarationDetailsContext.Provider>
                    </div>
               </div>
          </>
     )
}

const ConsultantDeclarationDetailsContext = createContext<IConsultantDeclarationDetailsContextPropsModel>({} as IConsultantDeclarationDetailsContextPropsModel)
export const useConsultantDeclarationDetailsContext = () => useContext(ConsultantDeclarationDetailsContext)

interface IConsultantDeclarationDetailsContextPropsModel {
     navigationStep: "arrets" | "ddc" | "cra" | "ndf" | "tr"
     setNavigationStep: Dispatch<SetStateAction<"arrets" | "ddc" | "cra" | "ndf" | "tr">>
     handleDemandeRectification: () => void
     handleValidation: () => void
     handleValidationDemandeRegul: () => void
     handleRejetDemandeRegul: () => void
     isActionDemandeRectifAvailable: boolean
     isActionValidationAvailable: boolean
     isActionValiderOuRejeterDemandeRegulAvailable: boolean
}

interface IConsultantDeclarationDetailsPropsModel {
     declaration: IConsultantDeclarationMensuelleModel
     defaultStep?: "arrets" | "ddc" | "cra" | "ndf" | "tr"
     handleDemandeRectificationCallback?: (val: IConsultantDeclarationMensuelleModel) => void
     handleValidationCallback?: (val: IConsultantDeclarationMensuelleModel) => void
     handleValidationDemandeRegulCallback?: (val: IConsultantDeclarationMensuelleModel) => void
     handleRejetDemandeRegulCallback?: (val: IConsultantDeclarationMensuelleModel) => void
}

export default ConsultantDeclarationDetails
