import { PageLink, PageTitle } from "@common-metronic/layout/core"
import { Navigate, Route, Routes } from "react-router-dom"
import Calculator from "./calculator/Calculator"

const ToolsRouting = () => {
     return (
          <Routes>
               <Route
                    path="/calculator/"
                    element={
                         <>
                              <PageTitle breadcrumbs={toolsCalculatorBreadcrumbs} />
                              <Calculator />
                         </>
                    }
               ></Route>

               <Route index element={<Navigate to="/tools/calculator/" />} />
          </Routes>
     )
}

const toolsBreadcrumbs: Array<PageLink> = [
     {
          title: "Outils",
          path: "/tools/",
     },
]
const toolsCalculatorBreadcrumbs: Array<PageLink> = [
     ...toolsBreadcrumbs,
     {
          title: "Calculatrice",
          path: `/tools/calculator/`,
     },
]
export default ToolsRouting
