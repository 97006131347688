import { usePersonnelCraContext } from "./_PersonnelCra"
import moment from "moment"
import { CONST_ENTITE_CODE_GS, CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_RTT, CONST_PERSONNEL_CRA_DETAILS_ABSENCE_TYPES_FOR_SELECT2 } from "@common-constants/*"
import { useAuth } from "../../../../AppContext"

const PersonnelCraTableTbodyAbsencesRows = () => {
     const { currentUser } = useAuth()
     const contextCra = usePersonnelCraContext()

     const AbsenceRow = ({ typeAbsence, typeAbsenceLabel }: { typeAbsence: string; typeAbsenceLabel: string }) => {
          return (
               <tr>
                    <td className={"ps-sm-6 border-end sticky-column"} style={{ fontSize: "0.8rem" }}>
                         {typeAbsenceLabel}
                    </td>
                    {contextCra.data.personnelCraDetails?.map((craDetails, key) => {
                         const absence = contextCra.data.personnelCraDetails?.find(
                              item => item.absence === typeAbsence && item.date?.format("DD/MM/YYYY") === craDetails.date?.format("DD/MM/YYYY")
                         )
                         const heuresAbsence = absence && absence.personnelCraDetailsHeures ? absence.personnelCraDetailsHeures[0]?.heuresAbsence : ""

                         return contextCra.editionModeEnabled ? (
                              <td
                                   key={key}
                                   className={`text-center heuresCell ${[5, 6].includes(craDetails.date?.weekday() as number) && "isWeekendCell"} ${
                                        moment().format("YYYYMMDD") === craDetails.date?.format("YYYYMMDD") && "isTodayCell"
                                   }`}
                                   onClick={() => contextCra.setSelectedCellRowAbsence({ item: craDetails, absenceType: typeAbsence, absenceTypeLabel: typeAbsenceLabel })}
                              >
                                   {heuresAbsence}
                              </td>
                         ) : (
                              <td
                                   key={key}
                                   className={`text-center heuresCell ${[5, 6].includes(craDetails.date?.weekday() as number) && "isWeekendCell"} ${
                                        moment().format("YYYYMMDD") === craDetails.date?.format("YYYYMMDD") && "isTodayCell"
                                   }`}
                              >
                                   {heuresAbsence}
                              </td>
                         )
                    })}
               </tr>
          )
     }

     return (
          <>
               <tr>
                    <td scope="row" className={"border-end sticky-column"}>
                         <span className={"text-primary fw-bold ps-sm-2"}>ABSENCES</span>
                    </td>
               </tr>

               {CONST_PERSONNEL_CRA_DETAILS_ABSENCE_TYPES_FOR_SELECT2.map((item, key) => {
                    if (item.value === CONST_PERSONNEL_CRA_DETAILS_ABSCENCE_RTT) {
                         return currentUser?.mainEntite?.code === CONST_ENTITE_CODE_GS && <AbsenceRow key={key} typeAbsence={item.value} typeAbsenceLabel={item.label} />
                    } else {
                         return <AbsenceRow key={key} typeAbsence={item.value} typeAbsenceLabel={item.label} />
                    }
               })}
          </>
     )
}

export default PersonnelCraTableTbodyAbsencesRows
