// Etat badge for column état
import { IConsultantDeclarationMensuelleModel } from "@common-models/*"
import {
     CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
     CONST_DECLARATION_SUIVI_TYPE_DEMANDE_REGUL_PAR_CONSULTANT,
     CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
     CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_ADMINISTRATIF,
} from "@common-constants/*"
import React from "react"
import { useAuth } from "../../../../../../AppContext"
import { useConsultantsDeclarationsListingContext } from "../ConsultantsDeclarationsListing"
import { IGetConsultantsDeclarationsDataItemResponseModel } from "../core/_models"

// Etat badge for cell column état
export const EtatBadge = ({ declaration }: { declaration: IConsultantDeclarationMensuelleModel | null }) => {
     const { currentUser } = useAuth()
     const doesConnectedUserHaveAccessToModuleAdmin = (() => {
          return currentUser?.authorizedAppModules?.find(item => item === CONST_PERSONNEL_APPLICATION_ACCESS_MODULE_ADMINISTRATIF) !== undefined
     })()

     if (declaration?.etat) {
          // Badge according to access
          if (doesConnectedUserHaveAccessToModuleAdmin) {
               if (
                    [
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-danger not-concerned-by-preview"}>En attente de réception</span>
               }
               if ([CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE].includes(declaration.etat?.type as string)) {
                    return <span className={"badge badge-warning not-concerned-by-preview"}>À valider</span>
               }
               if (
                    [
                         CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
                         CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,

                         CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
                         CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-info not-concerned-by-preview"}>En cours de rectification</span>
               }
               if (
                    [
                         CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_DEMANDE_REGUL_PAR_CONSULTANT,
                         CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Validée pour la paie</span>
               }
          } else {
               if (
                    [
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-danger not-concerned-by-preview"}>En attente de réception</span>
               }

               if (
                    [
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_VALIDATION_REGUL,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-warning not-concerned-by-preview"}>À valider</span>
               }

               if (
                    [
                         CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
                         CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
                         CONST_DECLARATION_SUIVI_TYPE_VALIDATION_REGUL_PAR_RESP,
                         CONST_DECLARATION_SUIVI_TYPE_RESP_VIENT_DE_DEMANDER_UNE_RECTIFICATION_APRES_SIGNATURE_APRES_DEMANDE_REGUL,
                    ].includes(declaration.etat?.type as string)
               ) {
                    return <span className={"badge badge-info not-concerned-by-preview"}>En cours de rectification</span>
               }

               if (
                    [CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE, CONST_DECLARATION_SUIVI_TYPE_DEMANDE_REGUL_PAR_CONSULTANT].includes(
                         declaration.etat?.type as string
                    )
               ) {
                    return <span className={"badge badge-warning not-concerned-by-preview"}>À contrôler</span>
               }
               if ([CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE].includes(declaration.etat?.type as string)) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Envoyée au siège</span>
               }

               if ([CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE, CONST_DECLARATION_SUIVI_TYPE_REJET_REGUL_PAR_RESP].includes(declaration.etat?.type as string)) {
                    return <span className={"badge badge-success not-concerned-by-preview"}>Validée pour la paie</span>
               }
          }
     }

     return <span className={"badge badge-danger not-concerned-by-preview"}>En attente de réception</span>
}

export const IconRelance = ({ item }: { item: IGetConsultantsDeclarationsDataItemResponseModel }) => {
     const context = useConsultantsDeclarationsListingContext()

     const ActionIcon = () => <span className={"fas fa-bell fs-1 text-warning text-hover-danger ms-4 not-concerned-by-preview"} onClick={() => context.handleRelance([item])} />

     return context.isRelancable(item) ? <ActionIcon /> : <></>
}
