import { usePersonnelCraContext } from "./_PersonnelCra"
import MyTooltip from "@common-utils/MyTooltip"
import moment from "moment"
import { IPersonnelCraDetailsHeureModel, IPersonnelCraModel } from "@common-models/*"
import { CONST_CIVILITE_MME, CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_MAIN, CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPES_OPTIONS_FOR_SELECT2 } from "@common-constants/*"
import { toast } from "react-toastify"
import useSwal from "@common-hooks/useSwal"
import { useAuth } from "../../../../AppContext"
import { deletePersonnelDeclarationCraLigneActivite } from "../core/_requests"
import { useQueryClient } from "react-query"

const PersonnelCraTableTbodyHeuresTravailRow = () => {
     const { swal } = useSwal()
     const { currentUser } = useAuth()
     const queryClient = useQueryClient()
     const contextCra = usePersonnelCraContext()
     const lignesActivites: string[] = Array.from(
          new Set(contextCra.data.personnelCraDetails?.flatMap(craDetails => craDetails.personnelCraDetailsHeures?.map(heure => heure?.type as string) || []) || [])
     )

     function deleteLigneActivite(type: string, typeLabel: string) {
          swal.fire({
               icon: "warning",
               title: `Êtes-vous sûr${currentUser?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir supprimer la ligne "${typeLabel}"?`,
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               focusConfirm: true,
               showLoaderOnConfirm: true,
               preConfirm: async () => {
                    return deletePersonnelDeclarationCraLigneActivite(contextCra.data.id as number, type)
                         .then(r => {
                              queryClient.setQueryData<IPersonnelCraModel | undefined>(contextCra.REACT_QUERY_KEY_GET_ACTIVE_CRA, () => {
                                   return r.data
                              })
                              toast.success("La ligne a été supprimée avec succès")
                         })
                         .catch(e => {
                              toast.error(e?.response?.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !swal.isLoading(),
          }).then()
     }

     return (
          <>
               <tr>
                    <td scope="row" className={"border-end sticky-column"}>
                         <span className={"d-flex align-items-center text-primary fw-bold ps-2"}>
                              ACTIVITÉS
                              {contextCra.editionModeEnabled && (
                                   <MyTooltip title={"Ajouter une ligne d'activité"}>
                                        <i className={"fas fa-plus-circle text-success ms-4 fs-2 p-0"} onClick={() => contextCra.setCreateLigneActivite(true)} />
                                   </MyTooltip>
                              )}
                         </span>
                    </td>
               </tr>

               {lignesActivites.map(type => {
                    const typeLabel = CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPES_OPTIONS_FOR_SELECT2.find(option => option.value === type)?.label
                    return (
                         <tr key={type}>
                              <td scope="row" className={"border-end sticky-column ps-2"}>
                                   <span className={"d-flex align-items-center text-primary fw-bold"}>
                                        {contextCra.editionModeEnabled && (
                                             <>
                                                  {type !== CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_MAIN && (
                                                       <MyTooltip title={"Supprimer cette ligne"}>
                                                            <i
                                                                 className={`fas fa-minus-circle text-danger fs-2 p-0 me-2 ms-4`}
                                                                 onClick={() => deleteLigneActivite(type, typeLabel as string)}
                                                            />
                                                       </MyTooltip>
                                                  )}
                                                  <MyTooltip title={"Compléter cette ligne"}>
                                                       <i
                                                            className={`fas fa-wand-magic-sparkles text-dark fs-2 p-0 me-2 ${
                                                                 type === CONST_PERSONNEL_CRA_DETAILS_HEURE_TYPE_MAIN ? "ms-4" : ""
                                                            }`}
                                                            onClick={() => contextCra.setAutocompleteHeuresActivite(type)}
                                                       />
                                                  </MyTooltip>
                                             </>
                                        )}
                                        {typeLabel}
                                   </span>
                              </td>
                              {contextCra.data.personnelCraDetails?.map((craDetails, key_) => {
                                   const heures = craDetails.personnelCraDetailsHeures!.filter(heure => heure.type === type)[0]
                                   return contextCra.editionModeEnabled ? (
                                        <td
                                             key={key_}
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date?.weekday() as number) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") === craDetails.date?.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                             onClick={() => contextCra.setSelectedCellRowActivite({ craDetails, heures: heures as IPersonnelCraDetailsHeureModel, type })}
                                        >
                                             <span>{heures && heures.type === type && heures.heuresJour && heures.heuresJour > 0 ? heures.heuresJour : ""}</span>
                                        </td>
                                   ) : (
                                        <td
                                             className={`text-center heuresCell ${[5, 6].includes(craDetails.date?.weekday() as number) && "isWeekendCell"} ${
                                                  moment().format("YYYYMMDD") === craDetails.date?.format("YYYYMMDD") && "isTodayCell"
                                             }`}
                                             key={key_}
                                        >
                                             <span>{heures && heures.heuresJour && heures.heuresJour > 0 && heures.type === type ? heures.heuresJour : ""} </span>
                                        </td>
                                   )
                              })}
                         </tr>
                    )
               })}
          </>
     )
}

export default PersonnelCraTableTbodyHeuresTravailRow
