import * as Yup from "yup"
import {
     CONST_CONSULTANT_CONTRAT_CDD,
     CONST_CONSULTANT_CONTRAT_CDI,
     CONST_CONSULTANT_CONTRAT_CDIC,
     CONST_CONSULTANT_STATUT_AGENT_MAITRISE,
     CONST_CONSULTANT_STATUT_CADRE,
     CONST_CONSULTANT_STATUT_ETAM,
     CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR,
} from "@common-constants/*"
import { Form, Formik, FormikHelpers } from "formik"
import { IDemanderInformationsPreEmbaucheParMailAuCandidatRequestModel } from "../core/_models"
import { entamerDemandeEmbauche } from "../core/_requests"
import { toast } from "react-toastify"
import { AxiosError } from "axios"
import { ICandidatModel, IConsultantDEModel, IHttpErrorResponseModel } from "@common-models/*"
import MyAlert from "@common-utils/MyAlert"
import MyRadioWithLabelAndDescriptionField from "@common-utils/fields/MyRadioWithLabelAndDescriptionField"
import MySelectField from "@common-utils/fields/MySelectField"
import MySelectGetAllDataFromServer from "@common-utils/fields/MySelectGetAllDataFromServer"
import { CONST_API_UTILS_GET_ENTITES_ENDPOINT } from "../../../../endpoints"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import React from "react"

interface CandidatEntamerDemandeEmbaucheProps {
     candidat: ICandidatModel
     callBackOnSubmit: (val: IConsultantDEModel) => void
}

const CandidatEntamerDemandeEmbauche = ({ candidat, callBackOnSubmit = () => {} }: CandidatEntamerDemandeEmbaucheProps) => {
     const schema = Yup.object().shape({
          typeContrat: Yup.string().label(`"Type de contrat"`).required(),
          contrat: Yup.string()
               .when("typeContrat", {
                    is: "TYPE_CONTRAT_SALARIE",
                    then: schema => schema.required(),
               })
               .label(`"Contrat"`),
          statut: Yup.string()
               .when("typeContrat", {
                    is: "TYPE_CONTRAT_SALARIE",
                    then: schema => schema.required(),
               })
               .label(`"Contrat"`),
          nationalite: Yup.string().label(`"Nationalité"`).required(),
          entiteRattrachement: Yup.number().label(`"Entité d'embauche"`).required(),
     })

     interface IDemanderInfoPreEmbaucheParEmailFormValuesModel {
          typeContrat?: "TYPE_CONTRAT_ST" | "TYPE_CONTRAT_PORTAGE" | "TYPE_CONTRAT_SALARIE"
          contrat?: typeof CONST_CONSULTANT_CONTRAT_CDI | typeof CONST_CONSULTANT_CONTRAT_CDD | typeof CONST_CONSULTANT_CONTRAT_CDIC
          statut?: typeof CONST_CONSULTANT_STATUT_ETAM | typeof CONST_CONSULTANT_STATUT_AGENT_MAITRISE | typeof CONST_CONSULTANT_STATUT_CADRE
          nationalite?: "NATIONALITE_FR" | "NATIONALITE_ETRANGERE"
          entiteRattrachement?: number
     }

     const initialValues: IDemanderInfoPreEmbaucheParEmailFormValuesModel = {}

     function handleSubmit(values: IDemanderInfoPreEmbaucheParEmailFormValuesModel, helpers: FormikHelpers<IDemanderInfoPreEmbaucheParEmailFormValuesModel>) {
          helpers.setStatus(null)

          const payload: IDemanderInformationsPreEmbaucheParMailAuCandidatRequestModel = {
               typeContrat: values.typeContrat as string,
               contrat: values.contrat,
               statut: values.statut,
               entiteRattrachement: values.entiteRattrachement as number,
               nationalite: values.nationalite as string,
          }
          entamerDemandeEmbauche(candidat?.id as number, payload)
               .then(r => {
                    callBackOnSubmit(r.data)
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Set form errors
                    if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                         for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                    }

                    // Set form global status and notify user using a toast
                    helpers.setStatus(error?.detail)
                    toast.error(error?.detail, { autoClose: false })

                    // Stop submit loader
                    helpers.setSubmitting(false)
               })
     }

     return (
          <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
               {helpers => {
                    return (
                         <Form noValidate autoComplete="off">
                              {helpers.status && (
                                   <MyAlert type={"danger"} classNames={"mb-4"}>
                                        {helpers.status}
                                   </MyAlert>
                              )}

                              <MyAlert type={"primary"} classNames={"mb-5"} isDismissible={false}>
                                   <>Si le candidat possède plusieurs nationalités dont une française, veuillez sélectionner la nationalité française.</>
                              </MyAlert>

                              {/* Field typeContrat */}
                              <label className="fw-bold fs-6 mb-4 required">Choisissez le type de contrat</label>
                              <div className="row mb-4">
                                   <div className="col-lg-4 mb-lg-0 mb-2">
                                        <MyRadioWithLabelAndDescriptionField
                                             name={"typeContrat"}
                                             value={"TYPE_CONTRAT_SALARIE"}
                                             isChecked={helpers.values.typeContrat === "TYPE_CONTRAT_SALARIE"}
                                             label={"Contrat salarié"}
                                             description={"Collaborateur avec un contrat en CDI, CDD ou CDI C"}
                                             iconFontAwesomeClassName={"user-tie"}
                                             color={"primary"}
                                             isInvalid={!!helpers.errors.typeContrat}
                                             onChange={val => helpers.setFieldValue("typeContrat", val)}
                                        />
                                   </div>
                                   <div className="col-lg-4 mb-lg-0 mb-2">
                                        <MyRadioWithLabelAndDescriptionField
                                             name={"typeContrat"}
                                             value={"TYPE_CONTRAT_ST"}
                                             isChecked={helpers.values.typeContrat === "TYPE_CONTRAT_ST"}
                                             label={"Contrat de sous traitance"}
                                             description={"Société de service, Frelance ..."}
                                             iconFontAwesomeClassName={"building"}
                                             color={"primary"}
                                             isInvalid={!!helpers.errors.typeContrat}
                                             onChange={val => helpers.setFieldValue("typeContrat", val)}
                                        />
                                   </div>
                                   <div className="col-lg-4">
                                        <MyRadioWithLabelAndDescriptionField
                                             name={"typeContrat"}
                                             value={"TYPE_CONTRAT_PORTAGE"}
                                             isChecked={helpers.values.typeContrat === "TYPE_CONTRAT_PORTAGE"}
                                             label={"Contrat de portage"}
                                             description={"Contrat tripartite avec une société de portage"}
                                             iconFontAwesomeClassName={"user"}
                                             color={"primary"}
                                             isInvalid={!!helpers.errors.typeContrat}
                                             onChange={val => helpers.setFieldValue("typeContrat", val)}
                                        />
                                   </div>
                              </div>
                              {helpers.errors.typeContrat && <div className={"text-danger"}>{helpers.errors.typeContrat}</div>}

                              {/* When typeContrat is filled*/}
                              {helpers.values.typeContrat && (
                                   <>
                                        <div className="separator separator-dashed mb-4" />
                                        {/* Précisez contrat & statut if typeContrat = "TYPE_CONTRAT_SALARIE"*/}
                                        {helpers.values.typeContrat === "TYPE_CONTRAT_SALARIE" && (
                                             <>
                                                  <div className="row mb-5">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez le contrat</label>

                                                       <div className="col-lg-8">
                                                            <MySelectField
                                                                 name={"contrat"}
                                                                 options={[
                                                                      { label: "CDI C", value: CONST_CONSULTANT_CONTRAT_CDIC },
                                                                      { label: "CDD", value: CONST_CONSULTANT_CONTRAT_CDD },
                                                                      { label: "CDI", value: CONST_CONSULTANT_CONTRAT_CDI },
                                                                 ]}
                                                                 value={helpers.values.contrat}
                                                                 onChange={val => helpers.setFieldValue("contrat", val)}
                                                                 isInvalid={!!helpers.errors.contrat}
                                                            />
                                                            {helpers.errors.contrat && <div className="text-danger">{helpers.errors.contrat}</div>}
                                                       </div>
                                                  </div>

                                                  <div className="row mb-5">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Précisez le statut</label>

                                                       <div className="col-lg-8">
                                                            <MySelectField
                                                                 name={"statut"}
                                                                 options={[
                                                                      { label: "ETAM", value: CONST_CONSULTANT_STATUT_ETAM },
                                                                      { label: "Agent de maîtrise", value: CONST_CONSULTANT_STATUT_AGENT_MAITRISE },
                                                                      { label: "Cadre", value: CONST_CONSULTANT_STATUT_CADRE },
                                                                 ]}
                                                                 value={helpers.values.statut}
                                                                 onChange={val => helpers.setFieldValue("statut", val)}
                                                                 isInvalid={!!helpers.errors.statut}
                                                            />
                                                            {helpers.errors.statut && <div className="text-danger">{helpers.errors.statut}</div>}
                                                       </div>
                                                  </div>
                                             </>
                                        )}

                                        {/* Field nationalité */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Nationalité</label>

                                             <div className="col-lg-8">
                                                  <MySelectField
                                                       name={"nationalite"}
                                                       options={[
                                                            { label: "Française", value: "NATIONALITE_FR" },
                                                            { label: "Etrangère", value: "NATIONALITE_ETRANGERE" },
                                                       ]}
                                                       value={helpers.values.nationalite}
                                                       onChange={val => helpers.setFieldValue("nationalite", val)}
                                                       isInvalid={!!helpers.errors.nationalite}
                                                  />
                                                  {helpers.errors.nationalite && <div className="text-danger">{helpers.errors.nationalite}</div>}
                                             </div>
                                        </div>

                                        {/* Field entite */}
                                        <div className="row mb-5">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Entité de rattachement</label>

                                             <div className="col-lg-8">
                                                  <MySelectGetAllDataFromServer
                                                       name={"entiteRattrachement"}
                                                       url={CONST_API_UTILS_GET_ENTITES_ENDPOINT}
                                                       value={helpers.values.entiteRattrachement}
                                                       onChange={val => helpers.setFieldValue("entiteRattrachement", val)}
                                                       isInvalid={!!helpers.errors.entiteRattrachement}
                                                  />
                                                  {helpers.errors.entiteRattrachement && <div className="text-danger">{helpers.errors.entiteRattrachement}</div>}
                                             </div>
                                        </div>
                                   </>
                              )}

                              {/* Submit button */}
                              <div className={"text-end"}>
                                   <button className={"btn btn-sm btn-primary d-inline-flex align-items-center"} type={"submit"} disabled={helpers.isSubmitting}>
                                        {!helpers.isSubmitting ? (
                                             <>
                                                  Initialiser <i className={"fas fa-play ms-1"} />
                                             </>
                                        ) : (
                                             <MySimpleSpinner size={"sm"} />
                                        )}
                                   </button>
                              </div>
                         </Form>
                    )
               }}
          </Formik>
     )
}

export default CandidatEntamerDemandeEmbauche
