import React, { useState } from "react"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import MyTooltip from "@common-utils/MyTooltip"
import { Link } from "react-router-dom"
import { IGetConsultantsListingItemResponseModel } from "../core/_models"
import { useConsultantsListingContext } from "./ConsultantsListing"
import { getSelectedItemPlusPreviousAndNextItem } from "./core/_helpers"
import { useConsultantsListingListContext } from "./_ConsultantsListingList"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { useThemeMode } from "@common-metronic/partials"

interface IListItemPropsModel {
     item: IGetConsultantsListingItemResponseModel
     handleClickItemForPreview: (e: React.MouseEvent<any>) => void
     isItemSelected: boolean
     isItemLoading: boolean
}

const ListItem = ({ item, handleClickItemForPreview, isItemSelected, isItemLoading }: IListItemPropsModel) => {
     const { mode } = useThemeMode()

     const globalContext = useConsultantsListingContext()
     const listContext = useConsultantsListingListContext()
     const [isAvatarLoading, setIsAvatarLoading] = useState<boolean>(!!item.consultant.candidat?.photoProfilDriveId)
     const ptf = item.detailsDuJour.clientPropal || item.consultant.clientPropal
     const responsable = item.detailsDuJour.personnel || item.consultant.personnel
     const etat = item.detailsDuJour.consultant_etat || item.consultant.etat

     function handleOnCheckItem() {
          if (!globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.consultant.id as number)) {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: [...prev.checkedElementsInPage, item.consultant.id as number],
                    areAllElementsInAllPagesChecked: false,
               }))
          } else {
               globalContext.setIsBulkSelectionEnabled(prev => ({
                    ...prev,
                    checkedElementsInPage: prev.checkedElementsInPage.filter(id => id !== item.consultant.id),
                    areAllElementsInAllPagesChecked: false,
               }))
          }
     }

     const ActionsIcons = () => {
          return (
               <>
                    <div>
                         <MyTooltip title={"Ajouter un suivi"}>
                              <button
                                   type={"button"}
                                   className={`btn btn-outline btn-outline-dark btn-sm btn-icon rounded-circle mb-2`}
                                   onClick={() => globalContext.setSelectedConsultantsForSuivi(prev => ({ ...prev, consultants: [item.consultant] }))}
                              >
                                   <i className="fas fa-calendar-plus fs-4" />
                              </button>
                         </MyTooltip>
                    </div>
               </>
          )
     }

     return (
          <MyLoadingOverlay isActive={isItemLoading}>
               <>
                    <div
                         className={`${isItemSelected ? "border-start border-2 border-primary" : ""}`}
                         onClick={e => handleClickItemForPreview(e)}
                         ref={isItemSelected ? listContext.selectedItemRef : null}
                    >
                         <div className={`p-4 ${isItemSelected ? "mb-4 bg-light" : "cursor-pointer"}`}>
                              {/* checkbox, Avatar, nom & métier*/}
                              <div className={"d-flex justify-content-between"}>
                                   {/* checkbox */}
                                   {globalContext.isBulkSelectionEnabled.enabled && (
                                        <div className={"form-check form-check-custom form-check-solid form-check-sm me-4"}>
                                             <input
                                                  type="checkbox"
                                                  className={"form-check-input cursor-pointer border border-secondary not-concerned-by-preview"}
                                                  checked={globalContext.isBulkSelectionEnabled.checkedElementsInPage.includes(item.consultant.id as number)}
                                                  onChange={() => handleOnCheckItem()}
                                             />
                                        </div>
                                   )}

                                   {/* Avatar, nom & métier */}
                                   <div className={"d-flex align-items-center"}>
                                        {/* Avatar */}
                                        <div className={"d-flex flex-column"}>
                                             <MyLoadingOverlay isActive={isAvatarLoading} styles={{ height: "50px", width: "50px", borderRadius: "50%" }} size={"sm"}>
                                                  <img
                                                       src={
                                                            item.consultant.candidat?.photoProfilDriveId
                                                                 ? FileAndDriveHelper.getThumbnailDriveUrl(item.consultant.candidat?.photoProfilDriveId)
                                                                 : toAbsoluteUrl(`/media/svg/avatars/${mode === "light" ? "blank" : "blank-dark"}.svg`)
                                                       }
                                                       onLoad={() => setIsAvatarLoading(false)}
                                                       style={{ height: "50px", width: "50px", borderRadius: "50%" }}
                                                       alt="Avatar"
                                                  />
                                             </MyLoadingOverlay>
                                        </div>
                                        {/* Nom & métier*/}
                                        <div className={"ms-4"}>
                                             <div className={"mb-1"}>
                                                  <span className={"fs-5 fw-bold me-2 d-flex align-items-center"}>
                                                       <Link to={`/business/consultants/${item.consultant.id}/details`}>{item.consultant.prenomNom}</Link>
                                                  </span>
                                             </div>
                                             <div>{item.consultant.metier?.nom}</div>
                                        </div>
                                   </div>

                                   {/* Contrat, état & statut */}
                                   <div className={"d-flex flex-column"}>
                                        <div className={"mb-1"}>
                                             <div className={`badge badge-primary me-1`}>{item.consultant.contratLabel}</div>
                                             <div className={`badge badge-dark`}>{item.consultant.statutLabel}</div>
                                        </div>
                                        <div
                                             className={`badge badge-${
                                                  ["ETAT_EMBAUCHE_AVEC_MISSION", "ETAT_EN_MISSION"].includes(etat as string) ? "success" : "warning text-black"
                                             } d-flex justify-content-center`}
                                        >
                                             {["ETAT_EMBAUCHE_AVEC_MISSION", "ETAT_EN_MISSION"].includes(etat as string) ? "En mission" : "Intercontrat"}
                                        </div>
                                   </div>
                              </div>
                         </div>

                         {isItemSelected && (
                              <div className={"row align-items-center px-4 pb-4"}>
                                   <div className={"col-8"}>
                                        {/* Date entrée / embauche*/}
                                        <div className={`d-flex align-items-center mb-2`}>
                                             <span className="fas fa-calendar-day fs-5 w-25px" />
                                             {item.consultant.contrat !== "CONTRAT_ST" ? "Embauché" : "Entré"}
                                             {item.consultant.civilite === "CIVILITE_MME" && "e"} le {item.consultant.dateEmbauche?.format("dddd D MMMM YYYY")}
                                        </div>
                                        {/* Client + TJM */}
                                        {ptf && (
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-building fs-5 w-25px" />
                                                  {ptf.client?.nom} {ptf.type === "TYPE_ASSISTANCE_TECHNIQUE" ? `à ${ptf.tjm}€ / jour` : "( Forfait )"}
                                             </div>
                                        )}
                                        {/* Coût */}
                                        <div className={`d-flex align-items-center mb-2`}>
                                             <span className="fas fa-euro-sign fs-5 w-25px" />
                                             {item.consultant?.coutBrutNonCharge}€{item.consultant.contrat !== "CONTRAT_ST" ? " brut / mois" : " / jour"}
                                        </div>

                                        {/* Responsable */}
                                        {responsable && (
                                             <div className={`d-flex align-items-center mb-2`}>
                                                  <span className="fas fa-user fs-5 w-25px" />
                                                  {responsable.prenomNom}
                                             </div>
                                        )}
                                        {item.consultant.etat === "ETAT_EMBAUCHE_SANS_MISSION" && (
                                             <div className={`d-flex align-items-center mb-2 text-warning`}>
                                                  <span className="fas fa-triangle-exclamation fs-5 w-25px" />
                                                  Embauche sur profil
                                             </div>
                                        )}
                                   </div>
                                   <div className="col text-end">
                                        <ActionsIcons />
                                   </div>
                              </div>
                         )}
                    </div>
               </>
          </MyLoadingOverlay>
     )
}

const ConsultantsListingListItem = ({ item, index }: { item: IGetConsultantsListingItemResponseModel; index: number }) => {
     const context = useConsultantsListingContext()

     const isItemSelected: boolean = context.itemInPreview !== null && context.itemInPreview.selectedItem.consultant.id === item.consultant.id
     const isItemLoading: boolean =
          context.itemInPreview !== null && context.itemInPreview.selectedItem.consultant.id === item.consultant.id && context.itemInPreview.isSelectedItemLoading

     // Shows item in preview
     function handleClickItemForPreview(e: React.MouseEvent<any>) {
          const clickedElement = e.target as HTMLElement
          // .not-concerned-by-preview elements are not concerned by the click
          if (!clickedElement.classList.contains("not-concerned-by-preview") && context.itemInPreview?.selectedItem.consultant.id !== item.consultant.id) {
               context.setAreFiltersVisible(false)

               context.setItemInPreview(
                    getSelectedItemPlusPreviousAndNextItem(item, index, context.listingQuery.data!.data, context.listingQuery.data!.page, context.listingQuery.data!.totalPages)
               )
          }
     }

     return <ListItem item={item} handleClickItemForPreview={handleClickItemForPreview} isItemSelected={isItemSelected} isItemLoading={isItemLoading} />
}

export default ConsultantsListingListItem
