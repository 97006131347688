import { IConsultantDeclarationMensuelleModel, IConsultantDeclarationMensuelleSuiviModel, IHttpErrorResponseModel } from "@common-models/*"
import { useQuery } from "react-query"
import { AxiosError } from "axios"
import { toast } from "react-toastify"
import { getDeclarationConsultantSuivi } from "./core/_requests"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import MyAlert from "@common-utils/MyAlert"
import React from "react"
import {
     CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM,
     CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME,
     CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE,
     CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE,
} from "@common-constants/*"

const REACT_QUERY_KEY_GET_DE_SUIVI = "REACT_QUERY_KEY_GET_DE_SUIVI"
const ConsultantDeclarationSuivi = ({ declaration }: IConsultantDeclarationSuiviPropsModel) => {
     // Query
     const suiviQuery = useQuery<IConsultantDeclarationMensuelleSuiviModel[], AxiosError>(REACT_QUERY_KEY_GET_DE_SUIVI, () => {
          return getDeclarationConsultantSuivi(declaration.id as number)
               .then(r => {
                    return r.data
               })
               .catch((e: AxiosError) => {
                    const error: IHttpErrorResponseModel = e.response?.data

                    // Toast error
                    toast.error(error?.detail, { autoClose: false })

                    throw e
               })
     })

     function faIconAccordingToTypeSuivi(type: any) {
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_FIRS_TIME) return "fa-check-circle"
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION) return "fa-paper-plane"
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_FIRST_TIME) return "fa-signature"
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VEUT_ET_PEUT_APPORTER_MODIFICATION_SANS_VALIDATION_PAR_BM_OU_SIEGE) return "fa-pencil"
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE) return "fa-check-circle"
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_AVOIR_APPORTER_RECTIFICATION_SANS_VALIDATION_BM_OU_SIEGE) return "fa-signature"
          if (type === CONST_DECLARATION_SUIVI_TYPE_BM_VIENT_DE_DEMANDER_UNE_RECTIFICATION_AU_CONSULTANT) return "fa-thumbs-down"
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_FINALISER_LA_SAISIE_APRES_DEMANDE_RECTIFICATION_PAR_BM) return "fa-check-circle"
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_MODIF_SANS_VALIDATION_BM_OU_SIEGE)
               return "fa-paper-plane"
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_REENVOYER_MAIL_SIGNATURE_DECLARATION_APRES_FINALISATION_DEMANDE_RECTIFICATION_BM) return "fa-paper-plane"
          if (type === CONST_DECLARATION_SUIVI_TYPE_CONSULTANT_VIENT_DE_SIGNER_APRES_DEMANDE_RECTIFICATION_BM) return "fa-signature"
          if (type === CONST_DECLARATION_SUIVI_TYPE_BM_A_VALIDE_ET_ENVOYE_DOCUMENTS_SIEGE) return "fa-thumbs-up"
          if (type === CONST_DECLARATION_SUIVI_TYPE_DEMANDER_RECTIFICATION_PAR_SIEGE) return "fa-thumbs-down"
          if (type === CONST_DECLARATION_SUIVI_TYPE_VALIDATION_PAR_SIEGE) return "fa-thumbs-up"
          return ""
     }

     return (
          <>
               {/* Is fetching */}
               {suiviQuery.isFetching && (
                    <div className={"text-center p-5"}>
                         <MySimpleSpinner size={"xl"} />
                    </div>
               )}

               {/* Is error */}
               {!suiviQuery.isFetching && suiviQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{suiviQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger d-flex align-items-center p-0 px-3 py-2"} onClick={() => suiviQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* Without data */}
               {!suiviQuery.isFetching && !suiviQuery.isError && suiviQuery.data!.length === 0 && <MyAlert type={"primary"}>Aucun suivi à afficher</MyAlert>}

               {/* With data */}
               {!suiviQuery.isFetching && !suiviQuery.isError && suiviQuery.data!.length > 0 && (
                    <>
                         <div className="timeline">
                              {suiviQuery.data!.map((suivi, key) => (
                                   <div className="timeline-item" key={key}>
                                        <div className="timeline-line w-40px"></div>
                                        <div className="timeline-icon symbol symbol-circle symbol-40px">
                                             <div className={`symbol-label text-dark`}>
                                                  <span className={`fas ${faIconAccordingToTypeSuivi(suivi.type)} fs-4`}></span>
                                             </div>
                                        </div>
                                        <div className="timeline-content mb-10 mt-n1">
                                             <div className="pe-3 mb-5">
                                                  <div className="fs-5 fw-bold mb-2">{suivi.typeLabel}</div>
                                                  <div className="d-flex align-items-center mt-1 fs-6">
                                                       <div className="text-dark me-2 fs-7">
                                                            Le {suivi.createdAt?.format("dddd DD MMMM YYYY à H[h]m")}{" "}
                                                            {suivi.personnel ? (
                                                                 <b className={"text-center"}>par {suivi.personnel.prenomNom}</b>
                                                            ) : (
                                                                 <b className={"text-dark"}>par {declaration.consultant?.prenomNom}</b>
                                                            )}
                                                       </div>
                                                  </div>
                                             </div>

                                             {suivi.commentaire && (
                                                  <div className="overflow-auto pb-5">
                                                       <p className={"m-0 mb-2 fw-bold"}>Commentaire</p>
                                                       <div className="d-flex align-items-center border border-dashed border-gray-300 rounded p-7">
                                                            <div>{suivi.commentaire}</div>
                                                       </div>
                                                  </div>
                                             )}
                                        </div>
                                   </div>
                              ))}
                         </div>
                    </>
               )}
          </>
     )
}

interface IConsultantDeclarationSuiviPropsModel {
     declaration: IConsultantDeclarationMensuelleModel
}

export default ConsultantDeclarationSuivi
