import { useQueryClient } from "react-query"
import { IConsultantDEsuiviModel, IConsultantModel } from "@common-models/*"
import MySimpleSpinner from "@common-utils/MySimpleSpinner"
import { IGetDemandesEmbaucheListingRequestModel } from "./core/_models"
import MyCard from "@common-utils/MyCard"
import MyLoadingOverlay from "@common-utils/MyLoadingOverlay"
import React, { useState } from "react"
import FileAndDriveHelper from "@common-helpers/FileAndDriveHelper"
import { toAbsoluteUrl } from "@common-metronic/helpers"
import {
     CONST_CONSULTANT_CONTRAT_ST,
     CONST_CONSULTANT_STATUT_ST_PORTAGE,
     CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE,
     CONST_PERSONNEL_CODE_HUGO_DUTERTRE,
     CONST_PERSONNEL_CODE_THOMAS_NAULEAU,
} from "@common-constants/*"
import { useAuth } from "../../../../AppContext"
import MyTooltip from "@common-utils/MyTooltip"
import { Link } from "react-router-dom"
import { useDemandeEmbaucheActions } from "../details/core/_hooks"
import MyModal from "@common-utils/MyModal"
import DemandeEmbaucheDetailsValidationSiege from "../details/_DemandeEmbaucheDetailsValidationSiege"
import DemandesEmbaucheListingItemSuivi from "../_DemandesEmbaucheListingItemSuivi"
import { toast } from "react-toastify"

const REACT_QUERY_KEY_GET_LISTING_QUERY = "REACT_QUERY_KEY_GET_LISTING_QUERY"

interface IDemandesEmbaucheListingItemPropsModel {
     item: IGetDemandesEmbaucheListingRequestModel
}

const DemandesEmbaucheListingItem = ({ item }: IDemandesEmbaucheListingItemPropsModel) => {
     const queryClient = useQueryClient()

     const { currentUser } = useAuth()
     const [validationSiegeInModal, setValidationSiegeInModal] = useState<{
          modalTitle: JSX.Element
          isModalFullScreen: boolean
     }>()
     const [modalWithSuivi, setModalWithSuivi] = useState<IConsultantModel>()
     const [isPhotoLoading, setIsPhotoLoading] = useState<boolean>(true)
     const photoPreview = item.photoDriveID ? FileAndDriveHelper.getThumbnailDriveUrl(item.photoDriveID) : toAbsoluteUrl("/media/svg/avatars/blank.svg")

     const isUserFromSiege = currentUser?.roleGroup === "ROLE_GROUP_ADMINISTRATIF"
     const isUserFromDirection = [CONST_PERSONNEL_CODE_HUGO_DUTERTRE, CONST_PERSONNEL_CODE_THOMAS_NAULEAU].find(item_ => item_ === currentUser?.code) !== undefined
     const isUserResponsable = item.consultant.personnel?.id === currentUser?.id
     const isUserResponsableOfResponsable = currentUser?.personnelHierarchyFromUpToDown?.find(item_ => item_.id === item.consultant.personnel?.id) !== undefined
     const actions = useDemandeEmbaucheActions(item.consultant.id as number)

     function handleAllActionsExceptDeletion(suivi: IConsultantDEsuiviModel) {
          queryClient.setQueryData<IGetDemandesEmbaucheListingRequestModel[] | undefined>(
               REACT_QUERY_KEY_GET_LISTING_QUERY,
               (prev: IGetDemandesEmbaucheListingRequestModel[] | undefined) => {
                    if (prev && prev.length > 0) {
                         const index = prev.findIndex(item_ => item_.consultant.id === item.consultant.id)
                         if (index >= 0) {
                              prev[index] = {
                                   ...prev[index],
                                   dernierSuiviDemandeEmbauche: suivi,
                              }
                         }
                         return prev
                    }

                    return prev
               }
          )
     }

     function deleteItemFromList() {
          queryClient.setQueryData<IGetDemandesEmbaucheListingRequestModel[] | undefined>(
               REACT_QUERY_KEY_GET_LISTING_QUERY,
               (prev: IGetDemandesEmbaucheListingRequestModel[] | undefined) => {
                    if (prev && prev.length > 0) {
                         return prev.filter(item_ => item_.consultant.id !== item.consultant.id)
                    }

                    return prev
               }
          )
     }

     const EtatDemandeEmbauche = () => {
          // Etats vus par le siège
          if (isUserFromSiege) {
               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_VALIDATION" || item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_ADMIN") {
                    return <div className={"bg-danger text-white p-1 fw-bold rounded-1 rounded-bottom-0"}>À valider</div>
               }
               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_ADMIN_DEMANDE_RECTIFICATION")
                    return <div className={"bg-warning p-1 fw-bold rounded-1 rounded-bottom-0"}>En cours de rectification</div>
          }

          if (isUserFromDirection) {
               if (
                    ["TYPE_IA_DEMANDE_EMBAUCHE", "TYPE_IARESP_VALIDATION", "TYPE_IA_RECTIFICATION_FOR_DG", "TYPE_IA_RECTIFICATION_FOR_IARESP"].find(
                         item_ => item_ === item.dernierSuiviDemandeEmbauche.type
                    )
               ) {
                    return <div className={"bg-danger text-white p-1 fw-bold rounded-1 rounded-bottom-0"}>À valider</div>
               }

               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_DEMANDE_RECTIFICATION")
                    return <div className={"bg-warning p-1 fw-bold rounded-1 rounded-bottom-0"}>En cours de rectification</div>
          }

          if (isUserResponsableOfResponsable) {
               return (
                    <>
                         {(item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_DEMANDE_EMBAUCHE" ||
                              item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_IARESP") && (
                              <div className={"bg-danger text-white p-1 fw-bold rounded-1 rounded-bottom-0"}>À valider</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_IARESP_DEMANDE_RECTIFICATION" && (
                              <div className={"bg-warning p-1 fw-bold rounded-1 rounded-bottom-0"}>En cours de rectification</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_DEMANDE_RECTIFICATION" && (
                              <div className={"bg-warning p-1 fw-bold rounded-1 rounded-bottom-0"}>À réctifier</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_ADMIN_DEMANDE_RECTIFICATION" && (
                              <div className={"bg-warning p-1 fw-bold rounded-1 rounded-bottom-0"}>À réctifier</div>
                         )}
                    </>
               )
          }

          if (isUserResponsable) {
               return (
                    <>
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_BROUILLON" && <div className={"bg-secondary p-1 fw-bold rounded-1 rounded-bottom-0"}>Brouillon</div>}
                         {(item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_DEMANDE_EMBAUCHE" ||
                              item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_ADMIN" ||
                              item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_DG" ||
                              item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_IARESP") && (
                              <div className={"bg-primary p-1 fw-bold rounded-1 rounded-bottom-0"}>En attente de validation</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_IARESP_VALIDATION" && (
                              <div className={"bg-success p-1 fw-bold rounded-1 rounded-bottom-0"}>Validé par responsable</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_IARESP_DEMANDE_RECTIFICATION" && (
                              <div className={"bg-warning p-1 fw-bold rounded-1 rounded-bottom-0"}>À réctifier</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_IARESP_REJET_DEFINITIF" && (
                              <div className={"bg-danger text-white p-1 fw-bold rounded-1 rounded-bottom-0"}>Rejeté</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_VALIDATION" && (
                              <div className={"bg-success p-1 fw-bold rounded-1 rounded-bottom-0"}>Validé par direction</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_DEMANDE_RECTIFICATION" && (
                              <div className={"bg-warning p-1 fw-bold rounded-1 rounded-bottom-0"}>À réctifier</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_REJET_DEFINITIF" && (
                              <div className={"bg-danger text-white p-1 fw-bold rounded-1 rounded-bottom-0"}>Rejeté</div>
                         )}
                         {item.dernierSuiviDemandeEmbauche.type === "TYPE_ADMIN_DEMANDE_RECTIFICATION" && (
                              <div className={"bg-warning p-1 fw-bold rounded-1 rounded-bottom-0"}>À réctifier</div>
                         )}
                    </>
               )
          }

          return <></>
     }

     const ActionsAccordingToEtatDemandeEmbauche = () => {
          if (isUserFromSiege) {
               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_VALIDATION" || item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_ADMIN") {
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <MyTooltip title={"Consulter"}>
                                   <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-primary p-0 px-3 py-2 ms-2"}>
                                        <i className={"fas fa-eye"} />
                                   </Link>
                              </MyTooltip>
                              <MyTooltip title={"Demander une rectification"}>
                                   <button className={"btn btn-sm btn-warning p-0 px-3 py-2 ms-2"} onClick={() => actions.demandeRectifParSiege(handleAllActionsExceptDeletion)}>
                                        <i className={"fas fa-hand-point-right"} />
                                   </button>
                              </MyTooltip>
                              {item.consultant.contrat !== "CONTRAT_ST" ? (
                                   <MyTooltip title={"Valider"}>
                                        <button
                                             className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2"}
                                             onClick={() =>
                                                  setValidationSiegeInModal({
                                                       modalTitle: <MySimpleSpinner size={"sm"} />,
                                                       isModalFullScreen: false,
                                                  })
                                             }
                                        >
                                             <i className={"fas fa-thumbs-up"} />
                                        </button>
                                   </MyTooltip>
                              ) : (
                                   <MyTooltip title={"Valider"}>
                                        <button
                                             className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2"}
                                             onClick={() =>
                                                  actions.validationSiege(() => {
                                                       deleteItemFromList()
                                                       setValidationSiegeInModal(undefined)
                                                  })
                                             }
                                        >
                                             <i className={"fas fa-thumbs-up"} />
                                        </button>
                                   </MyTooltip>
                              )}
                              <MyTooltip title={"Télécharger les documents"}>
                                   <button className={"btn btn-sm btn-dark p-0 px-3 py-2 ms-2 me-2"} onClick={() => actions.downloadDocs(item.consultant)}>
                                        <i className={"fas fa-download"} />
                                   </button>
                              </MyTooltip>
                         </div>
                    )
               }
               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_ADMIN_DEMANDE_RECTIFICATION")
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-primary p-0 px-3 py-2 ms-2"}>
                                   Consulter <i className={"fas fa-eye"} />
                              </Link>
                         </div>
                    )
          }

          if (isUserFromDirection) {
               if (["TYPE_IA_DEMANDE_EMBAUCHE", "TYPE_IARESP_VALIDATION", "TYPE_IA_RECTIFICATION_FOR_DG"].find(item_ => item_ === item.dernierSuiviDemandeEmbauche.type)) {
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <MyTooltip title={"Consulter"}>
                                   <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-primary p-0 px-3 py-2 ms-2"}>
                                        <i className={"fas fa-eye"} />
                                   </Link>
                              </MyTooltip>
                              <MyTooltip title={"Rejeter définitivement"}>
                                   <button
                                        className={"btn btn-sm btn-danger p-0 px-3 py-2 ms-2"}
                                        onClick={() => actions.rejetDefinitifParDirection(handleAllActionsExceptDeletion)}
                                   >
                                        <i className={"fas fa-thumbs-down"} />
                                   </button>
                              </MyTooltip>
                              <MyTooltip title={"Demander une rectification"}>
                                   <button
                                        className={"btn btn-sm btn-warning p-0 px-3 py-2 ms-2"}
                                        onClick={() => actions.demandeRectifParDirection(handleAllActionsExceptDeletion)}
                                   >
                                        <i className={"fas fa-hand-point-right"} />
                                   </button>
                              </MyTooltip>
                              <MyTooltip title={"Valider"}>
                                   <button className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2"} onClick={() => actions.validationDirection(handleAllActionsExceptDeletion)}>
                                        <i className={"fas fa-thumbs-up"} />
                                   </button>
                              </MyTooltip>
                         </div>
                    )
               }

               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_DEMANDE_RECTIFICATION") {
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <MyTooltip title={"Consulter"}>
                                   <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-primary p-0 px-3 py-2 ms-2"}>
                                        Consulter <i className={"fas fa-eye"} />
                                   </Link>
                              </MyTooltip>
                         </div>
                    )
               }
          }

          if (isUserResponsableOfResponsable) {
               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_DEMANDE_EMBAUCHE" || item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_IARESP") {
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <MyTooltip title={"Consulter"}>
                                   <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-primary p-0 px-3 py-2 ms-2"}>
                                        <i className={"fas fa-eye"} />
                                   </Link>
                              </MyTooltip>
                              <MyTooltip title={"Rejeter définitivement"}>
                                   <button
                                        className={"btn btn-sm btn-danger p-0 px-3 py-2 ms-2"}
                                        onClick={() => actions.rejetDefinitifParResponsable(handleAllActionsExceptDeletion)}
                                   >
                                        <i className={"fas fa-thumbs-down"} />
                                   </button>
                              </MyTooltip>
                              <MyTooltip title={"Demander une rectification"}>
                                   <button
                                        className={"btn btn-sm btn-warning p-0 px-3 py-2 ms-2"}
                                        onClick={() => actions.demandeRectifParResponsable(handleAllActionsExceptDeletion)}
                                   >
                                        <i className={"fas fa-hand-point-right"} />
                                   </button>
                              </MyTooltip>
                              <MyTooltip title={"Valider"}>
                                   <button className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2"} onClick={() => actions.validationResp(handleAllActionsExceptDeletion)}>
                                        <i className={"fas fa-thumbs-up"} />
                                   </button>
                              </MyTooltip>
                         </div>
                    )
               }

               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_IARESP_DEMANDE_RECTIFICATION") {
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <MyTooltip title={"Consulter"}>
                                   <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-primary p-0 px-3 py-2 ms-2"}>
                                        Consulter <i className={"fas fa-eye"} />
                                   </Link>
                              </MyTooltip>
                         </div>
                    )
               }
          }

          if (isUserResponsable) {
               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_BROUILLON") {
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-info p-0 px-3 py-2 ms-2"}>
                                   Compléter <i className={"fas fa-edit"} />
                              </Link>
                              <MyTooltip title={"Supprimer la demande"}>
                                   <button className={"btn btn-sm btn-danger p-0 px-3 py-2 ms-2"} onClick={() => actions.deleteDemande().then(() => deleteItemFromList())}>
                                        <i className={"fas fa-trash"} />
                                   </button>
                              </MyTooltip>
                              {item.isDemandeEmbaucheFullyCompleted && (
                                   <MyTooltip title={"Terminer et envoyer"}>
                                        <button className={"btn btn-sm btn-success p-0 px-3 py-2 ms-2"} onClick={() => actions.soumission(handleAllActionsExceptDeletion)}>
                                             <i className={"fas fa-paper-plane"} />
                                        </button>
                                   </MyTooltip>
                              )}
                         </div>
                    )
               }

               if (item.dernierSuiviDemandeEmbauche.type === "TYPE_IARESP_REJET_DEFINITIF" || item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_REJET_DEFINITIF") {
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-primary p-0 px-3 py-2 ms-2"}>
                                   Consulter <i className={"fas fa-eye"} />
                              </Link>
                              <MyTooltip title={"Supprimer la demande"}>
                                   <button className={"btn btn-sm btn-danger p-0 px-3 py-2 ms-2"} onClick={() => actions.deleteDemande(handleAllActionsExceptDeletion)}>
                                        <i className={"fas fa-trash"} />
                                   </button>
                              </MyTooltip>
                         </div>
                    )
               }

               if (
                    item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_DEMANDE_EMBAUCHE" ||
                    item.dernierSuiviDemandeEmbauche.type === "TYPE_IARESP_VALIDATION" ||
                    item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_VALIDATION" ||
                    item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_IARESP" ||
                    item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_DG" ||
                    item.dernierSuiviDemandeEmbauche.type === "TYPE_IA_RECTIFICATION_FOR_ADMIN"
               ) {
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-primary p-0 px-3 py-2 ms-2"}>
                                   Consulter <i className={"fas fa-eye"} />
                              </Link>
                         </div>
                    )
               }

               if (
                    item.dernierSuiviDemandeEmbauche.type === "TYPE_IARESP_DEMANDE_RECTIFICATION" ||
                    item.dernierSuiviDemandeEmbauche.type === "TYPE_DG_DEMANDE_RECTIFICATION" ||
                    item.dernierSuiviDemandeEmbauche.type === "TYPE_ADMIN_DEMANDE_RECTIFICATION"
               ) {
                    return (
                         <div className={"d-flex justify-content-center"}>
                              <Link to={`/recrutement/demandes-embauche/${item.consultant.id}/details`} className={"btn btn-sm btn-warning p-0 px-3 py-2 ms-2"}>
                                   Corriger <i className={"fas fa-edit"} />
                              </Link>
                         </div>
                    )
               }
          }

          return <></>
     }

     function handleValidationAdministrativeCallback(generationOuUpload: "GENERATION_OU_UPLOAD__GENERATION" | "GENERATION_OU_UPLOAD__UPLOAD") {
          if (generationOuUpload === "GENERATION_OU_UPLOAD__UPLOAD") {
               toast.success("Votre validation a bien été prise en compte.")
               // DE ends at this point
               deleteItemFromList()
               setValidationSiegeInModal(undefined)
          } else {
               // Admin must complete contrat généré and then proceed for validation
               setValidationSiegeInModal(prev =>
                    prev
                         ? {
                                ...prev,
                                modalTitle: (
                                     <div className={"d-flex"}>
                                          Complétion du contrat
                                          <button
                                               className={"btn btn-sm btn-success p-0 px-2 py-1 d-flex align-items-center ms-2"}
                                               onClick={() =>
                                                    actions.validationSiege(() => {
                                                         deleteItemFromList()
                                                         setValidationSiegeInModal(undefined)
                                                    })
                                               }
                                          >
                                               Terminer et valider <i className={"fas fa-thumbs-up ms-1"} />
                                          </button>
                                     </div>
                                ),
                                isModalFullScreen: true,
                           }
                         : prev
               )
          }
     }

     return (
          <>
               <MyTooltip title={<span>Voir suivi</span>}>
                    <div className="text-center cursor-pointer" onClick={() => setModalWithSuivi(item.consultant)}>
                         <EtatDemandeEmbauche />
                    </div>
               </MyTooltip>

               <MyCard classNames={"rounded-top-0"}>
                    <MyCard.Body>
                         <div className={"d-flex justify-content-center mb-2"}>
                              <MyLoadingOverlay isActive={isPhotoLoading} styles={{ borderRadius: "50%", width: "80px", height: "80px" }}>
                                   <img src={photoPreview} style={{ borderRadius: "50%", width: "80px", height: "80px" }} onLoad={() => setIsPhotoLoading(false)} alt={"Avatar"} />
                              </MyLoadingOverlay>
                         </div>
                         <h4 className={"text-center"}>{item.consultant.prenomNom}</h4>
                         <h6 className={"text-center text-primary"}>
                              {item.consultant.contrat !== CONST_CONSULTANT_CONTRAT_ST && "Salarié"}
                              {item.consultant.statut === CONST_CONSULTANT_STATUT_ST_PORTAGE && "Portage"}
                              {item.consultant.statut === CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE && "Société de service / Freelance"}
                         </h6>

                         <div className="separator separator-dashed mt-2 mb-3" />

                         <p>
                              <span className={"fw-bold"}>Métier: </span> {item.consultant.metier ? item.consultant.metier.nom : "Non renseigné"}
                         </p>
                         <p>
                              <span className={"fw-bold"}>Nationalité: </span> {item.consultant.nationalite ? item.consultant.nationalite.nationalite : "Non renseignée"}
                         </p>
                         <p>
                              <span className={"fw-bold"}>Date d'embauche:</span>{" "}
                              {item.consultant.dateEmbauche ? item.consultant.dateEmbauche.format("dddd D MMMM YYYY") : "Non renseignée"}
                         </p>
                         <p>
                              <span className={"fw-bold"}>Responsable:</span> {item.consultant.personnel?.prenomNom}
                         </p>

                         {item.consultant.clientPropal && (
                              <p>
                                   <span className={"fw-bold"}>Client:</span> {item.consultant.clientPropal.client?.nom}
                              </p>
                         )}

                         {item.consultant.etat === "ETAT_EN_COURS_EMBAUCHE_SANS_MISSION" && (
                              <p>
                                   <span className={"fw-bold"}>Embauche sur profil</span>
                              </p>
                         )}

                         <p>
                              <span className={"fw-bold"}>Entité:</span> {item.consultant.entite?.nom}
                         </p>

                         <div className="separator separator-dashed mb-4" />
                         <ActionsAccordingToEtatDemandeEmbauche />
                    </MyCard.Body>
               </MyCard>

               {validationSiegeInModal && (
                    <MyModal
                         title={validationSiegeInModal.modalTitle}
                         show={true}
                         handleClose={() => setValidationSiegeInModal(undefined)}
                         size={"lg"}
                         fullscreen={validationSiegeInModal.isModalFullScreen ? true : undefined}
                         noPadding={validationSiegeInModal.isModalFullScreen}
                    >
                         <DemandeEmbaucheDetailsValidationSiege
                              consultant_ID={item.consultant.id as number}
                              onContractLoadCallback={val => {
                                   const title = val ? (
                                        <div className={"d-flex"}>
                                             Complétion du contrat
                                             <button
                                                  className={"btn btn-sm btn-success p-0 px-2 py-1 d-flex align-items-center ms-2"}
                                                  onClick={() =>
                                                       actions.validationSiege(() => {
                                                            deleteItemFromList()
                                                            setValidationSiegeInModal(undefined)
                                                       })
                                                  }
                                             >
                                                  Terminer et valider <i className={"fas fa-thumbs-up ms-1"} />
                                             </button>
                                        </div>
                                   ) : (
                                        <>Génération / saisie du contrat de travail</>
                                   )
                                   setValidationSiegeInModal(prev => (prev ? { ...prev, modalTitle: title, isModalFullScreen: !!val } : prev))
                              }}
                              onSubmitCallback={handleValidationAdministrativeCallback}
                         />
                    </MyModal>
               )}
               {modalWithSuivi && (
                    <MyModal title={<>Suivi d'embauche de {modalWithSuivi.prenomNom}</>} show={true} handleClose={() => setModalWithSuivi(undefined)}>
                         <DemandesEmbaucheListingItemSuivi consultant_ID={modalWithSuivi?.id as number} />
                    </MyModal>
               )}
          </>
     )
}

export default DemandesEmbaucheListingItem
